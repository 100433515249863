import type {
  Image,
  Product,
  VariantGroup,
  VariantContainer,
  VariantValue,
} from 'graphql-types/generated/types-generated';
import { parseHydraImage } from 'utils/imageUtils';

const mapColorVariants = ({ productPicture: Image }: VariantValue): Image => ({
  altText: Image?.altText,
  cdnFilename: Image?.cdnFilename,
  fileType: Image?.fileType,
  realFilename: Image?.realFilename,
  seoText: Image?.seoText,
});

export const getMediaDataPresentation = (
  colorVariants?: VariantValue[] | null,
  presentation?: Image[] | null,
): Image[] =>
  !colorVariants?.length
    ? [...(presentation || [])]
    : [...(presentation || []), ...colorVariants.map(mapColorVariants)];

export const getColorVariants = (variantContainerData?: VariantContainer | null): VariantGroup => {
  if (variantContainerData?.variantGroups?.length) {
    const colorVariants = variantContainerData.variantGroups.find(
      (variantGroup) => variantGroup.name === 'color',
    );

    const variants = colorVariants?.variantValues || [];

    if (variants.length > 1) {
      return {
        ...colorVariants,
        variantValues: variants
          .filter((variantValue) => variantValue.productPicture)
          .sort(
            (variantValueA, variantValueB) =>
              Number(variantValueB.selected) - Number(variantValueA.selected),
          )
          .map((variantValue) => ({
            ...variantValue,
            id: variantValue.product?.code,
            selected: false,
          })),
      };
    }
  }

  return {};
};

export const filterMediaPresentation = (presentations: Image[]) => {
  if (!presentations) {
    return [];
  }

  return presentations
    .filter((presentation) => presentation.fileType === 'IMAGE')
    .map((image) => parseHydraImage(image) ?? {});
};

export const getElementIndex = (element: HTMLAnchorElement): number => {
  const listElement = element.parentElement;
  return Array.prototype.indexOf.call(listElement?.parentElement?.children, listElement);
};

export const getCurrentProduct = (
  variants: VariantGroup,
  index: number,
): Product | null | undefined => variants.variantValues?.[index]?.product;
