import React, { useCallback, useEffect, useState } from 'react';
import Zendesk, { ZendeskAPI } from 'react-zendesk';
import { webWidget, handleZendeskLoaded } from 'components/Zendesk/ZendeskWidget.config';

interface ZendeskWidgetProps {
  /* apiKey of the zenDesk */
  zenDeskApiKey: string;
  /* if the Zendesk button appears on the page */
  isRestricted: boolean;
}

const ZendeskWidget = (props: ZendeskWidgetProps) => {
  const { zenDeskApiKey, isRestricted } = props;
  const [isLoaded, setIsLoaded] = useState(false);
  const [zendeskConsent, setZendeskConsent] = useState('hide');

  const handleZendeskConsent = useCallback(() => {
    const vendorConsent = window?.jentis?.consent?.engine?.getVendorConsent('cstm_zendesk');
    const zendesState = isRestricted && vendorConsent ? 'show' : 'hide';
    setZendeskConsent(zendesState);
  }, [isRestricted]);

  useEffect(() => {
    // event listeners for when cookie bar settings for vendors initialize or user changes allowed vendors
    document.addEventListener('jentis.consent.engine.init', handleZendeskConsent);
    document.addEventListener('jentis.consent.engine.vendor-change', handleZendeskConsent);

    // remove event listeners when component unmounts
    return () => {
      document.removeEventListener('jentis.consent.engine.init', handleZendeskConsent);
      document.removeEventListener('jentis.consent.engine.vendor-change', handleZendeskConsent);
    };
  }, [handleZendeskConsent]);

  useEffect(() => {
    handleZendeskLoaded();
  }, [isLoaded]);

  useEffect(() => {
    ZendeskAPI('webWidget', zendeskConsent);
  }, [zendeskConsent, isLoaded]);

  return (
    <Zendesk
      defer
      zendeskKey={zenDeskApiKey}
      webWidget={webWidget}
      onLoaded={() => setIsLoaded(true)}
    />
  );
};

export default ZendeskWidget;
