import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useCategoryContext } from 'pages/CategoryView/provider/Category.provider';
import useLogger from 'hooks/useLogger';

const LocationListener = () => {
  const logger = useLogger();
  const location = useLocation();
  const { searchTracking } = useCategoryContext();

  useEffect(() => {
    fetch('/api/tracking', {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ url: window.location.href, eventType: 'pageView' }),
    }).catch(logger);
  }, [location.pathname, logger]);

  useEffect(() => {
    searchTracking.current.method ||= 'location_change';
  }, [location, searchTracking]);

  return null;
};

export default LocationListener;
