import React, { useState, useEffect, useId, type FC, useCallback, ChangeEvent, memo } from 'react';
import classnames from 'classnames';
import { local } from '@xxxlgroup/hydra-utils/storage';
import { halfCircleFilled } from '@xxxlgroup/hydra-icons';
import { useTracking } from 'utils/tracking/hooks';
import useMessage from 'components/Message/useMessage';
import { Icon } from '@xxxlgroup/hydra-ui-components';
import { useAuth } from 'modules/customer-authentication/AuthContext';
import useSavePreferences from 'modules/customer-account/hooks/useSavePreferences';
import {
  PreferenceActions,
  ContrastValues,
} from 'modules/customer-account/pages/PreferencesData/preferences.types';
import useLazyQueryAuth from 'modules/customer-authentication/hooks/useLazyQueryAuth';
import { GET_PREFERENCES } from 'modules/customer-account/pages/UserData/UserData.query';

import styles from 'components/ContrastToggle/ContrastToggle.scss';

const CONTRAST_MODE = 'contrastmode';
const DATA_CONTRAST_MODE = 'data-contrastmodeenabled';

export enum ColorScheme {
  dark,
  light,
}

export const getContrastValue = () => local.getItem(CONTRAST_MODE);
export const setContrastValue = (contrast: ContrastValues) =>
  local.setItem(CONTRAST_MODE, contrast);

export const hasContrastEnabled = () => {
  const contrast = getContrastValue();
  return contrast === ContrastValues.DARK;
};

export const writeContrastToggleStatusToDOM = () => {
  const isEnabled = hasContrastEnabled();

  if (isEnabled) {
    document.body.setAttribute(DATA_CONTRAST_MODE, 'true');
  } else {
    document.body.removeAttribute(DATA_CONTRAST_MODE);
  }
};

interface ContrastToggleProps {
  className?: string;
  colorScheme?: ColorScheme;
}

const ContrastToggle: FC<ContrastToggleProps> = ({ className, colorScheme = ColorScheme.dark }) => {
  const inputId = useId();
  const contrastLabel = useMessage('a11y.contrast.toggle.label');

  const [isContrastEnabled, setIsContrastEnabled] = useState<boolean>(hasContrastEnabled());
  const tracking = useTracking({ className, colorScheme }, 'ContrastToggle');
  const { isAnonymous } = useAuth();
  const { savePreferences } = useSavePreferences();
  const storageEventListener = useCallback(() => {
    setIsContrastEnabled(hasContrastEnabled());
  }, []);

  const checkAndUpdateContrast = (preferencesContrast: ContrastValues | null) => {
    const contrast = getContrastValue();

    if (preferencesContrast !== contrast) {
      if (contrast) {
        savePreferences(PreferenceActions.SET_CONTRAST, { contrast });
        return;
      }
      if (preferencesContrast) {
        setContrastValue(preferencesContrast);
        document.dispatchEvent(new Event('storage'));
      }
    }
  };

  const getPreferences = useLazyQueryAuth(GET_PREFERENCES, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const preferencesContrast = data?.getPreferences?.data?.contrast;
      checkAndUpdateContrast(preferencesContrast);
    },
  });

  useEffect(() => {
    document.addEventListener('storage', storageEventListener);

    return () => {
      document.removeEventListener('storage', storageEventListener);
    };
  }, [storageEventListener]);

  useEffect(() => {
    writeContrastToggleStatusToDOM();
  }, [isContrastEnabled]);

  useEffect(() => {
    if (!isAnonymous) {
      getPreferences.load();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAnonymous]);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const isChecked = event.target.checked;
      const contrast = isChecked ? ContrastValues.DARK : ContrastValues.LIGHT;
      setContrastValue(contrast);

      !isAnonymous && savePreferences(PreferenceActions.SET_CONTRAST, { contrast });

      document.dispatchEvent(new Event('storage'));

      tracking(event, {
        context: [],
        props: {
          status: isChecked ? 'active' : 'inactive',
        },
        purpose: 'contrastToggle',
        type: 'Toggle',
      });
    },
    [isAnonymous, savePreferences, tracking],
  );

  return (
    <>
      <input
        checked={isContrastEnabled}
        role="switch"
        id={inputId}
        onChange={handleChange}
        className={styles.checkbox}
        type="checkbox"
      />
      <label
        htmlFor={inputId}
        className={classnames(
          styles.label,
          {
            [styles.dark]: colorScheme === ColorScheme.dark,
          },
          className,
        )}
      >
        {contrastLabel}
        <span className={styles.toggleContainer} role="presentation">
          <Icon className={styles.icon} glyph={halfCircleFilled} />
        </span>
      </label>
    </>
  );
};

export default memo(ContrastToggle);
