import React, { FC } from 'react';
import classnames from 'classnames';
import track from 'react-tracking';
import { Checkbox, Content } from '@xxxlgroup/hydra-ui-components';
import { asField, FieldApi, FieldState } from 'informed';

import Message from 'components/Message';
import { useTracking } from 'utils/tracking/hooks';
import { tagComponent } from 'utils/tracking/tracking';

import styles from 'components/Form/components/FormCheckbox/FormCheckbox.scss';

export interface FormCheckboxProps {
  /** text for checkbox */
  checkboxText?: string;
  /** if undefined or null, value of fieldState.value prop is rendered */
  isChecked?: boolean;
  /** react children */
  children?: React.ReactNode;
  /** className for external styling */
  className?: string;
  /** class name for content text */
  contentClassName?: string;
  /** name of the field (used for the data structure) */
  field: string;
  /** @see FormTypes#fieldApi */
  fieldApi: FieldApi;
  /** @see FormTypes#booleanFieldState */
  fieldState: FieldState<boolean>;
  /** label of the checkbox */
  label?: React.ReactNode;
  /** className for label */
  labelClassName?: string;
  /** message code for the label code (automatic one will be overwritten) that will be used if label is not provided */
  labelCode?: string;
  /** onBlur handler function */
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  /** onChange handler function */
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, isChecked: boolean) => void;
  /** message code for placeholder */
  placeholderCode?: string;
}

const FormCheckbox: FC<FormCheckboxProps> = (props) => {
  const tracking = useTracking(props, 'FormCheckbox');
  const getChecked = (value: boolean, isChecked?: boolean | null) =>
    isChecked === undefined || isChecked === null ? value : isChecked;

  const {
    isChecked,
    className,
    contentClassName,
    field,
    label,
    labelClassName,
    labelCode,
    checkboxText,
    onChange,
    onBlur,
    ...other
  } = props;

  const handleBlur = (event: React.FocusEvent<HTMLInputElement, Element>) => {
    tracking(event);
    const { setTouched } = props.fieldApi;
    setTouched(true);

    if (onBlur) {
      onBlur(event);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    tracking(event, {
      value: isChecked,
    });
    const { setValue } = props.fieldApi;
    setValue(isChecked);

    if (onChange) {
      onChange(event, isChecked);
    }
  };

  const { value, error } = props.fieldState;

  const renderContent = (content: string | string[]) => (
    <div className={classnames(className, styles.checkBox)}>
      <Checkbox
        aria-label={content}
        data-purpose={`form.checkbox.${field}`}
        checked={getChecked(value, isChecked)}
        onChange={handleChange}
        onBlur={handleBlur}
        {...other}
      >
        <span className={labelClassName}>
          {label || <Content className={contentClassName} content={content} size="sm" tag="span" />}
        </span>
      </Checkbox>
      {error && (
        <div className={styles.error} data-purpose="form.checkbox.error" role="alert">
          {error}
        </div>
      )}
    </div>
  );

  return checkboxText ? (
    renderContent(checkboxText)
  ) : (
    <Message code={[labelCode || `wxs.form.checkbox.${field}.label`]}>
      {(labelText) => renderContent(labelText)}
    </Message>
  );
};

export default track(tagComponent('FormCheckbox'))(asField(FormCheckbox));
