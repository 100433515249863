import React, { FC } from 'react';
import { Content } from '@xxxlgroup/hydra-ui-components';

import styles from 'components/Listing/components/Divider/Divider.scss';

interface DividerProps {
  /** info text to be displayed in divider */
  content: string;
  /** Divider ID for scroll reference */
  dividerId: string;
}

const Divider: FC<DividerProps> = (props) => {
  const { content, dividerId } = props;

  return (
    <Content
      id={dividerId}
      content={content}
      tag="div"
      className={styles.divider}
      data-purpose="listing.productsContainer.divider"
    />
  );
};

export default Divider;
