import React, { FC } from 'react';
import classnames from 'classnames';
import EnergyEfficiency from 'components/EnergyEfficiency';
import Eyecatcher from 'components/ProductTile/components/Eyecatcher/Eyecatcher';
import ToggleFavouriteButton from 'modules/favourites/components/ToggleFavouriteButton';
import {
  EnergyEfficiencyData,
  Eyecatcher as EyecatcherType,
} from 'graphql-types/generated/types-generated';

interface LabelsProps {
  /** Sets a class to give customized styles */
  className?:
    | string
    | {
        productTileGrid?: string;
        productTileGridDetailsWrapper?: string;
      };
  /** Product code  */
  code?: string;
  /** Adds the energyEfficiencyLabel */
  energyEfficiencyData?: EnergyEfficiencyData | null;
  /** Eyecatchers of product */
  eyecatchers?: EyecatcherType[] | null;
  /** indicates if the product tile is rendering the reduced version */
  isDense?: boolean;
  /** indicates if the favourites is enabled */
  isFavouritesEnabled?: boolean;
  /** The percentage of the saved price */
  savedPercent?: string | number | null;
  /** CSS styles */
  styles?: {
    dense?: string;
    denseEELabel?: string;
    energyEfficiencyLabel?: string;
    /** apply style if there is no eel information */
    hasNoEnergyLabelData?: string;
    eyecatcher?: string;
    favoriteWrapper?: string;
    labelsWrapper?: string;
  };
}

const Labels: FC<LabelsProps> = ({
  className,
  code = '',
  energyEfficiencyData = null,
  eyecatchers = [],
  isDense = false,
  isFavouritesEnabled = false,
  styles,
  savedPercent = null,
}) => {
  const hasDataInformation = !!energyEfficiencyData?.datasheet || !!energyEfficiencyData?.label;

  return (
    <div className={styles?.labelsWrapper}>
      {!isDense && eyecatchers?.[0]?.style && (
        <Eyecatcher
          eyecatchers={eyecatchers}
          savedPercent={savedPercent}
          className={styles?.eyecatcher}
        />
      )}

      {energyEfficiencyData && (
        <EnergyEfficiency
          className={classnames(styles?.energyEfficiencyLabel, {
            [styles?.hasNoEnergyLabelData as string]: !hasDataInformation,
            [styles?.denseEELabel as string]: isDense,
          })}
          energyEfficiencyData={energyEfficiencyData}
          data-testid="energyEfficiencyData"
          hasInfo
        />
      )}

      {isFavouritesEnabled && (
        <ToggleFavouriteButton
          isAnimated
          code={code}
          className={classnames(className, styles?.favoriteWrapper, {
            [styles?.dense as string]: isDense,
          })}
        />
      )}
    </div>
  );
};

export default Labels;
