import React, { FC } from 'react';
import useMessage from 'components/Message/useMessage';
import Link from 'components/WebshopLink';
import type { EnergyEfficiencyLinkProps } from 'components/EnergyEfficiency/EnergyEfficiency.types';

const EUROPEAN_DATASHEET = 'EU Produktdatenblatt';

const EnergyEfficiencyLink: FC<EnergyEfficiencyLinkProps> = ({ datasheets, className }) => {
  const [productDataSheet, title] = useMessage(
    ['product.detail.productDataSheet', 'product.detail.productDataSheet'],
    {},
    true,
  );
  const euDataSheet = datasheets?.find((sheet) => sheet?.documentType === EUROPEAN_DATASHEET)?.url;

  return euDataSheet ? (
    <Link className={className} href={euDataSheet} title={title} target="_blank">
      {productDataSheet}
    </Link>
  ) : null;
};

export default EnergyEfficiencyLink;
