import { useState, useEffect, useCallback } from 'react';

const useExitIntent = () => {
  const [isExitIntent, setIsExitIntent] = useState(false);
  const root = document.getElementById('root') as HTMLElement;

  const checkMouseExitIntent = useCallback(
    (event: MouseEvent) => {
      if (event.clientY < 0) {
        setIsExitIntent(true);
        root.removeEventListener('mouseleave', checkMouseExitIntent);
      }
    },
    [root],
  );
  useEffect(() => {
    root.addEventListener('mouseleave', checkMouseExitIntent);

    return () => {
      root.removeEventListener('mouseleave', checkMouseExitIntent);
    };
  }, [checkMouseExitIntent, root]);
  return isExitIntent;
};

export default useExitIntent;
