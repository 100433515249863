import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import classnames from 'classnames';
import { HorizontalScrolling } from '@xxxlgroup/hydra-ui-components';
import createIntersectionObserver from '@xxxlgroup/hydra-utils/observers';
import { AnchorBarProps, RefType } from 'components/AnchorBar/AnchorBar.types';
import Anchor from 'components/AnchorBar/components/Anchor';
import Dropdown from 'components/AnchorBar/components/Dropdown/Dropdown';
import styles from 'components/AnchorBar/AnchorBar.scss';
import { parseHashValue } from 'utils/url';
import { debouncing } from 'utils/function';

const AnchorBar = ({ anchors, classNameAnchorBar, children, className, i18n }: AnchorBarProps) => {
  // Contains index of selected anchor
  const [activeIndex, setActiveIndex] = useState(0);
  // Contains element index that first goes out of viewport
  const [firstHiddenIndex, setHiddenIndex] = useState<number>(-1);
  // Contains flag for sticky behaviour
  const [isFixed, setFixed] = useState<boolean>(false);
  // Contains ref for red line that highlights active element
  const indicatorRef = useRef<RefType>(null);
  // Navigation ref
  const navRef = useRef<RefType>(null);
  // Contains ref to wrapper to control sticky behaviour
  const wrapperRef = useRef<RefType>(null);
  // Ref to HorizontalScrolling for resetting scrolled position
  const scrollNavRef = useRef<RefType>(null);

  const hasHiddenElements = firstHiddenIndex > -1;
  const showMoreText = i18n?.showMoreText;

  const detectFirstHidden = useCallback(() => {
    const nav = navRef?.current;
    if (!nav) {
      return;
    }
    const items = nav.querySelectorAll(`.${styles.anchor}`);
    const hiddenIndex: number = Array.from(items).findIndex((anchor): boolean => {
      if (anchor) {
        const { offsetLeft, offsetWidth } = anchor as HTMLElement;
        return offsetLeft + offsetWidth > nav.offsetWidth;
      }
      return false;
    });
    setHiddenIndex(hiddenIndex);
  }, []);

  const animateIndicator = useCallback(() => {
    const indicator = indicatorRef.current;
    const nav = navRef.current;
    if (indicator && nav) {
      const anchor = nav.querySelectorAll(`.${styles.anchor}`)[activeIndex];
      if (anchor) {
        const { offsetLeft, offsetWidth } = anchor as HTMLElement;
        indicator.style.cssText = `width: ${offsetWidth}px; left: ${offsetLeft}px`;
      }
    }
  }, [activeIndex]);

  const onSelect = (index: number, url: string) => {
    const navHeight = navRef?.current?.offsetHeight || 0;
    const hash = parseHashValue(url);
    const element = document.getElementById(hash);
    if (element) {
      const { top } = element.getBoundingClientRect();
      // navigate to anchor
      window.scrollTo({
        top: window.scrollY + top - navHeight + 2, // 2px to be sure that active element change is triggered
        behavior: 'smooth',
      });
      setActiveIndex(anchors.findIndex((anchor) => anchor.url.endsWith(element.id)));
    }
  };

  const handleScroll = useCallback(() => {
    if (wrapperRef?.current) {
      const { height, top } = wrapperRef.current.getBoundingClientRect();
      const isInViewPort: boolean = top <= 0 && top + height >= 0;
      setFixed(isInViewPort);
    }
  }, []);

  const dropdownAnchors = useMemo(
    () => (hasHiddenElements ? anchors.slice(firstHiddenIndex) : []),
    [anchors, firstHiddenIndex, hasHiddenElements],
  );

  // Update indicator when new element is selected
  useEffect(() => {
    animateIndicator();
  }, [activeIndex, animateIndicator, firstHiddenIndex]);

  // Reset HorizontalScrolling position after anchorbar width had been changed
  useEffect(() => {
    detectFirstHidden();
    const scroller = scrollNavRef?.current;
    if (scroller) {
      scroller.reset();
    }
  }, [firstHiddenIndex, isFixed, detectFirstHidden, showMoreText]);

  // Hide / Show anchors on resize
  useEffect(() => {
    const debouncedDetectFirstHidden = debouncing(detectFirstHidden, 100);
    window.addEventListener('resize', debouncedDetectFirstHidden);
    return () => window.removeEventListener('resize', debouncedDetectFirstHidden);
  }, [detectFirstHidden]);

  useEffect(() => {
    const debouncedHandleScroll = debouncing(handleScroll, 100);
    window.addEventListener('scroll', debouncedHandleScroll);
    return () => window.removeEventListener('scroll', debouncedHandleScroll);
  }, [handleScroll]);

  useEffect(() => {
    const anchoredBlocks = anchors.reduce<HTMLElement[]>((arr, item) => {
      const id: string = parseHashValue(item.url);
      const element = document.getElementById(id);
      if (element) {
        return [...arr, element];
      }
      return arr;
    }, []);

    const navHeight = navRef?.current?.offsetHeight || 0;

    const observer = createIntersectionObserver(
      (entries) => {
        entries.forEach(({ target, boundingClientRect: { top } }) => {
          // detect that block is near to edge
          if (Math.floor(top - navHeight) <= 1) {
            const index = anchors.findIndex((anchor) => anchor.url.endsWith(target.id));
            index > -1 && setActiveIndex(index);
          }
        });
      },
      {
        rootMargin: `-${navHeight}px 0px 0px 0px`,
        threshold: 1,
        root: null,
      },
    );

    anchoredBlocks.forEach((elm) => {
      observer.observe(elm);
    });

    return () => observer.disconnect();
  }, [anchors]);

  const renderAnchors = () =>
    anchors.map(({ url, linkName }, index) => {
      const isActive = index === activeIndex;
      const isHidden = hasHiddenElements && index >= firstHiddenIndex;
      const handleSelect = () => onSelect(index, url);
      return (
        <Anchor
          key={url}
          className={styles.anchor}
          isActive={isActive}
          isParentFixed={isFixed}
          isHidden={isHidden}
          onSelect={handleSelect}
          linkName={linkName}
        />
      );
    });
  const showIndicator = !hasHiddenElements || (hasHiddenElements && activeIndex < firstHiddenIndex);
  const indicatorClassName = classnames(styles.indicator, {
    [styles.hidden]: !showIndicator,
  });

  return (
    <div className={classnames(styles.wrapper, className)} ref={wrapperRef}>
      <div
        className={classnames(styles.top, {
          [styles.fixed]: isFixed,
        })}
        data-testid="top-panel"
      >
        <div className={classnames(styles.anchorbar, classNameAnchorBar)}>
          <div ref={navRef} className={styles.navigation}>
            <HorizontalScrolling
              listType="nav"
              listClassName={styles.list}
              hideFadeStart
              ref={scrollNavRef}
            >
              {renderAnchors()}
              <div className={indicatorClassName} ref={indicatorRef} />
            </HorizontalScrolling>
          </div>
          {hasHiddenElements && (
            <Dropdown
              activeIndex={activeIndex}
              anchors={dropdownAnchors}
              className={styles.dropdown}
              firstHiddenIndex={firstHiddenIndex}
              onSelect={onSelect}
            >
              <Anchor
                className={styles.trigger}
                isActive={activeIndex >= firstHiddenIndex}
                linkName={`${dropdownAnchors.length} ${showMoreText}`}
              />
            </Dropdown>
          )}
        </div>
      </div>
      {isFixed && <div className={styles.placeholder} role="presentation" />}
      {children}
    </div>
  );
};

export default AnchorBar;
