import { FormFieldContext } from 'components/Form/components/FormFieldProvider';
import { ApplicationFeature } from 'graphql-types/generated/types-generated';
import { useContext, useMemo } from 'react';

export interface FormFeature extends ApplicationFeature {
  required: boolean;
  visible: boolean;
}

const DEFAULT_FIELD_FLAGS = { visible: false, required: false };

/**
 * @typedef Feature
 * @type {Object}
 * @property {string} code
 * @property {boolean} enabled
 */

/**
 * @param {Array.<Feature>} features
 * @param {String} formFeatureFlag
 */
const getFormFeatureFlag = (features: ApplicationFeature[], formFeatureFlag: string): FormFeature =>
  features.reduce((fieldFlags: FormFeature, { code: currentCode, enabled }): FormFeature => {
    if (currentCode === `${formFeatureFlag}.visible`) {
      return {
        ...fieldFlags,
        visible: !!enabled,
      };
    }
    if (currentCode === `${formFeatureFlag}.required`) {
      return {
        ...fieldFlags,
        required: !!enabled,
      };
    }
    return fieldFlags;
  }, DEFAULT_FIELD_FLAGS as FormFeature);

/**
 * Checks the status of one FormFeatureFlags and returns visible and required
 * @param {String} formFeatureFlag
 * @returns {visible, required}
 */
const useFormFeatureFlags = (formFeatureFlag: string) => {
  const { features = [], loading = false } = useContext(FormFieldContext) || {};
  return useMemo(
    () => (loading ? DEFAULT_FIELD_FLAGS : getFormFeatureFlag(features, formFeatureFlag)),
    [loading, features, formFeatureFlag],
  );
};

export default useFormFeatureFlags;
