import React, { useContext } from 'react';
import useMessage from 'components/Message/useMessage';
import { SmartEditContext } from 'components/SmartEditHandling';

export interface MessageProps {
  /** @ignore */
  children?: (messages: string | string[]) => JSX.Element;
  /** one or multiple message code that will be translated */
  code: string | string[];
  /** disable inline editing in smartedit */
  isInlineEditDisabled?: boolean;
  /** translation of the message if it is known up front */
  messageText?: string;
  /** values to replace placeholders */
  values?: Record<string, string | number>;
}

const Message = (props: MessageProps): JSX.Element => {
  const { children, code, values, messageText, isInlineEditDisabled } = props;
  const { isInSmartEdit } = useContext(SmartEditContext);
  const translation = useMessage(code, values, isInlineEditDisabled || !!children);

  if (messageText) {
    return <>{messageText}</>;
  }

  if (children && isInSmartEdit && !isInlineEditDisabled) {
    return (
      <span
        className="smartEditTranslation"
        data-smartedit-component-type="LocalizationEntry"
        data-smartedit-component-id={code}
        data-smartedit-translation={translation}
      >
        {children(translation)}
      </span>
    );
  }

  return children ? children(translation) : <>{translation}</>;
};

export default Message;
