import { sanitize } from 'isomorphic-dompurify';
import React, { useCallback, useContext, useMemo } from 'react';

import ApplicationDataContext from 'pages/App/components/ApplicationDataProvider/ApplicationDataContext';
import { SmartEditContext } from 'components/SmartEditHandling';
import useLanguage from 'hooks/useLanguage';
import I18nContext from 'Providers/I18nContext';

const allowedTags = ['a', 'b', 'br', 'em', 'i', 'li', 'ol', 'p', 's', 'span', 'strong', 'ul'];
const allowedAttributes = ['target'];

const wrapSmartEditMessage = (code: string, message: string) => (
  <span
    key={code}
    className="smartEditTranslation"
    data-smartedit-component-type="LocalizationEntry"
    data-smartedit-component-id={code}
    data-smartedit-translation={message}
  >
    {message}
  </span>
);

const sanitizeTranslation = (message: string) => {
  let sanitizedTranslation = sanitize(message, {
    ALLOWED_TAGS: allowedTags,
    ADD_ATTR: allowedAttributes,
  });

  if (
    typeof sanitizedTranslation === 'object' &&
    typeof (sanitizedTranslation as object).toString === 'function'
  ) {
    sanitizedTranslation = (sanitizedTranslation as object).toString();
  }

  // fix ampersand and &nbsp; issue after updating DOMPurity version, see: https://github.com/cure53/DOMPurify/issues/379
  return sanitizedTranslation.replace(/&nbsp;/g, ' ').replace(/&amp;/g, '&');
};

type MessagesObject = Record<string, string>;

/**
 * Translations provided are editable in SmartEdit!
 * If you don't want to edit translation or for some reason you want to avoid wrapper for SmartEdit use param disableSmartEdit.
 * If the disableSmartEdit is set to true editing will not be able, but translation will be displayed.
 * @param code String String[]
 * @param values Object with key as variable to replace in the template string and value as the real value to replace with
 * @param disableSmartEdit
 * @returns {*}
 */
const useMessage = <T extends string | string[]>(
  code: T,
  values?: Record<string, string | number | null | undefined> | null,
  disableSmartEdit?: boolean,
): T => {
  const applicationData = useContext(ApplicationDataContext);
  const { isInSmartEdit } = useContext(SmartEditContext);
  const language = useLanguage();
  const i18n = useContext(I18nContext);

  const getMessageString = useCallback(
    (messageCode: string, messages?: MessagesObject) => {
      let i18nextOptions = {
        lng: language.code,
      };
      let sanitizedTranslation = null;

      const message = messages?.[messageCode];

      if (message) {
        if (!i18n.exists(messageCode, i18nextOptions)) {
          sanitizedTranslation = sanitizeTranslation(message);
          i18n.addResource(language.code, 'translation', messageCode, sanitizedTranslation);
        }
      }

      if (values) {
        i18nextOptions = { ...values, ...i18nextOptions };
      }

      try {
        return i18n.t(messageCode, i18nextOptions);
      } catch (e) {
        console.warn(e); // eslint-disable-line no-console
      }

      if (!sanitizedTranslation && message) {
        sanitizedTranslation = sanitizeTranslation(message);
      }

      return sanitizedTranslation;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(values)],
  );

  const codeAsJson = JSON.stringify(code);

  const fetchMessages = useCallback(() => {
    const { messages, updateMessage } = applicationData || {};

    let messageToWrap;

    if (Array.isArray(code)) {
      messageToWrap = code.map((c) => getMessageString(c, messages));
    } else {
      messageToWrap = getMessageString(code, messages);
    }

    if (isInSmartEdit) {
      window.smartedit = window.smartedit || {};
      window.smartedit.renderTranslation = (key: string, translation: string) => {
        updateMessage && updateMessage(key, translation);
      };

      if (!disableSmartEdit) {
        const isArray = Array.isArray(messageToWrap);

        return isArray
          ? messageToWrap.map((message: string, index: number) =>
              wrapSmartEditMessage(code[index], message),
            )
          : wrapSmartEditMessage(String(code), messageToWrap);
      }
    }

    return messageToWrap;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    applicationData.isLoading,
    codeAsJson,
    getMessageString,
    isInSmartEdit,
    disableSmartEdit,
    wrapSmartEditMessage,
  ]);

  return useMemo(() => fetchMessages(), [fetchMessages]);
};

export default useMessage;
