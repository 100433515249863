import React from 'react';
import PropTypes from 'prop-types';

import styles from 'components/Footer/components/LegalText/LegalText.scss';

const LegalText = ({ text }) => (
  <div id="legalInformation" className={styles.legalText}>
    {text}
  </div>
);

LegalText.propTypes = {
  /** Content of the component */
  text: PropTypes.string,
};

LegalText.defaultProps = {
  text: 'legalInformation',
};

export default LegalText;
