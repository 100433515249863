import React, { ReactElement, useMemo } from 'react';
import ComponentMapper from 'cms/ComponentMapper';
import useFeatureFlagsEnabled from 'hooks/useFeatureFlagsEnabled';
import useDynamicContentService from 'hooks/useDynamicContentService';

interface ContentBlockProps {
  /** className of the content slot */
  className?: string;
  /** Type of content slot component */
  area: string;
}

const ContentBlock: (props: ContentBlockProps) => ReactElement<any, any> | null = ({
  className,
  area,
}) => {
  const isGeneralBannerAreaDisabled = useFeatureFlagsEnabled(
    'poseidon.general.banner.area.disabled',
  );

  const { slots } = useDynamicContentService();
  const components = useMemo(() => {
    const slot = slots.find((item) => item.code === area);
    return slot?.components || [];
  }, [area, slots]);

  if (
    !area ||
    (area === 'general.banner' && isGeneralBannerAreaDisabled) ||
    components.length < 1
  ) {
    return null;
  }

  const renderSlot = (component: any) => (
    <ComponentMapper key={area} content={{ ...component, area, components }} />
  );

  return (
    <div data-purpose={area} className={className}>
      {components?.map(renderSlot)}
    </div>
  );
};

export default ContentBlock;
