import React, { FC } from 'react';
import classNames from 'classnames';
import type { Eyecatcher as eyecatcherGenerated } from 'graphql-types/generated/types-generated';
import type { EyecatcherType } from '@xxxlgroup/hydra-ui-components/dist/common/types/typeDefinitions';
import { Eyecatcher as HydraEyecatcher } from '@xxxlgroup/hydra-ui-components';
import useFeatureFlagsEnabled from 'hooks/useFeatureFlagsEnabled';
import useMessage from 'components/Message/useMessage';
import styles from 'components/ProductTile/components/Eyecatcher/Eyecatcher.scss';

interface EyecatcherProps {
  /** eyecatcher of product */
  eyecatchers: eyecatcherGenerated[];
  /** The percentage of the saved price */
  savedPercent: string | number | null;
  /** CSS styles */
  className?: string;
}

const Eyecatcher: FC<EyecatcherProps> = ({ eyecatchers, savedPercent, className }) => {
  const { style, code = '' } = eyecatchers[0];
  const isBlackFridayEnabled = useFeatureFlagsEnabled('eyecatcher.black.friday.version.enabled');
  const isSaleEyecatcher = code === 'sale';
  const isBlackFridayEyecatcher = isBlackFridayEnabled && isSaleEyecatcher;

  const isPercentageInvalid = !savedPercent || savedPercent === 0;
  const isPriceRegulationVariant = isSaleEyecatcher && isPercentageInvalid;

  const [eyecatcherTextPriceRegulationVariant, eyecatcherTextDefault, blackFridayText] = useMessage(
    [
      'product.eyecatcher.sale.priceRegulationVariant',
      `wxs.product.eyecatcher.${code}`,
      'product.eyecatcher.sale.blackFriday',
    ],
    { sale: code === 'sale' ? `${savedPercent}%` : '' },
  );

  const eyecatcherContent = isPriceRegulationVariant
    ? eyecatcherTextPriceRegulationVariant
    : eyecatcherTextDefault;

  return (
    <HydraEyecatcher
      type={style as EyecatcherType}
      className={classNames(className, { [styles.blackFriday]: isBlackFridayEyecatcher })}
      content={isBlackFridayEyecatcher ? blackFridayText : eyecatcherContent}
    />
  );
};

export default Eyecatcher;
