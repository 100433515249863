import React, { FC } from 'react';
import classnames from 'classnames';

import { BoxProps } from 'components/Box/Box.types';
import styles from 'components/Box/Box.scss';

const Box: FC<BoxProps> = ({
  alignItems = 'stretch',
  children,
  className,
  direction = 'row',
  display = 'block',
  justifyContent = 'flexStart',
  margin = 'none',
  marginBottom,
  marginLeft,
  marginRight,
  marginTop,
  marginX,
  marginY,
  padding = 'none',
  paddingBottom,
  paddingLeft,
  paddingRight,
  paddingTop,
  paddingX,
  paddingY,
  position = 'static',
  tag = 'div',
  width,
  wrap = 'nowrap',
  ...other
}) => {
  const Tag = tag;

  // create classes like .{margin}-{top}-{medium}
  const createStyles = (styleMap: object, attribute: string) =>
    Object.entries(styleMap).map((entry) => styles[`${attribute}-${entry[0]}-${entry[1]}`]);

  // combine spacing or use default
  const margins = {
    top: marginTop || marginY || margin,
    right: marginRight || marginX || margin,
    bottom: marginBottom || marginY || margin,
    left: marginLeft || marginX || margin,
  };

  const paddings = {
    top: paddingTop || paddingY || padding,
    right: paddingRight || paddingX || padding,
    bottom: paddingBottom || paddingY || padding,
    left: paddingLeft || paddingX || padding,
  };

  const style = width ? { style: { width } } : {};

  // collect all styling-related options
  const styling = classnames(
    styles[`display${display}`],
    styles[`items${alignItems}`],
    styles[`content${justifyContent}`],
    styles[`direction${direction}`],
    styles[`wrap${wrap}`],
    styles[`position${position}`],
    createStyles(margins, 'margin'),
    createStyles(paddings, 'padding'),
    className,
  );

  return (
    <Tag {...other} className={styling} {...style}>
      {children}
    </Tag>
  );
};

export default Box;
