import React, { FC, RefObject } from 'react';
import { Select } from '@xxxlgroup/hydra-ui-components';
import { SelectEventType } from '@xxxlgroup/hydra-ui-components/dist/common/components/Select/Select.types';
import classnames from 'classnames';
import { asField, FieldApi, FieldState } from 'informed';
import track from 'react-tracking';
import { useTracking } from 'utils/tracking/hooks';
import useMessage from 'components/Message/useMessage';
import { tagComponent } from 'utils/tracking/tracking';

export interface FormSelectBoxProps {
  /** className for external styling */
  className?: string;
  /** select ref to be able to focus when firstError is displayed  */
  forwardedRef?: RefObject<HTMLDivElement>;
  /** name of the field  */
  field: string;
  /** @see FormTypes#fieldApi */
  fieldApi: FieldApi;
  /** @see FormTypes#fieldState */
  fieldState: FieldState<any>;
  /** message code for the label code (automatic one will be overwritten) */
  labelCode?: string;
  /** key of options-object which should be displayed as selected option */
  keyName: string;
  /** key of options-object which should get returned on selection */
  keyValue: string;
  /** onBlur handler function */
  onBlur?: (event: React.FocusEvent<HTMLDivElement, Element>) => void;
  /** onChange handler function */
  onChange?: (id: string | null | undefined) => void;
  /** options of select */
  options: Record<string, any>[];
  /** symbol or text which is shown if the field is optional (not required). Default: wxs.form.optional */
  optionalSymbol?: string;
  /** flag whether the optionalSymbol will be shown */
  withOptionalSymbol?: boolean;
  /** message code for the placeholder code (automatic one will be overwritten) */
  placeholderCode?: string;
  /** value of selected item */
  selectedOption?: string;
}

const FormSelectBox: FC<FormSelectBoxProps> = (props) => {
  const tracking = useTracking(props, 'FormSelectBox');
  const {
    onChange,
    onBlur,
    className,
    labelCode,
    placeholderCode,
    field,
    fieldState: { value, error },
    fieldApi: { setTouched, setValue },
    forwardedRef,
    keyName,
    keyValue,
    selectedOption,
    options,
    optionalSymbol = 'wxs.form.optional',
    withOptionalSymbol,
    ...other
  } = props;
  const [label, placeholder, optionalSymbolTrans] = useMessage([
    labelCode || `form.select.${field}.label`,
    placeholderCode || `form.select.${field}.placeholder`,
    optionalSymbol,
  ]);

  const handleBlur = (event: React.FocusEvent<HTMLDivElement, Element>) => {
    tracking(event);
    setTouched(true);

    if (onBlur) {
      onBlur(event);
    }
  };

  const handleChange = (event: SelectEventType | undefined, id: string | null | undefined) => {
    tracking(event, { value: id });
    const hasId = !!id || id === '';
    hasId && setValue(id);

    if (onChange) {
      onChange(id);
    }
  };

  return (
    <div className={classnames(className)}>
      <Select
        aria-label={label}
        error={error}
        data-purpose={`form.select.${field}`}
        label={label}
        keyName={keyName}
        keyValue={keyValue}
        options={options}
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder={placeholder}
        ref={forwardedRef}
        selectedOption={selectedOption || value}
        optionalSymbol={withOptionalSymbol ? optionalSymbolTrans : undefined}
        {...other}
      />
    </div>
  );
};

export default track(tagComponent('FormSelectBox'))(asField(FormSelectBox));
