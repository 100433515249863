import React, { ReactNode } from 'react';
import useExitIntent from 'components/ExitIntent/useExitIntent';

interface ExitIntentProps {
  children: ((isExitIntent: boolean) => ReactNode) | ReactNode;
}

const ExitIntent: React.FC<ExitIntentProps> = (props) => {
  const { children } = props;
  const isExitIntent = useExitIntent();

  const renderChildren = () => (typeof children === 'function' ? children(isExitIntent) : children);
  return <>{renderChildren()}</>;
};

export default ExitIntent;
