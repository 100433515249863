import React, {
  FC,
  KeyboardEventHandler,
  MouseEvent,
  MutableRefObject,
  RefObject,
  useEffect,
  useRef,
  useState,
} from 'react';
import classnames from 'classnames';
import { useTracking } from 'utils/tracking/hooks';
import useMessage from 'components/Message/useMessage';
import { EnergyEfficiencyLabel, EnergyEfficiencyLabel2021 } from '@xxxlgroup/hydra-ui-components';
import EnergySideSheet from 'components/EnergyEfficiency/components/EnergySideSheet';
import type { EnergyEfficiencyProps } from 'components/EnergyEfficiency/EnergyEfficiency.types';

import styles from 'components/EnergyEfficiency/EnergyEfficiency.scss';

const EnergyEfficiency: FC<EnergyEfficiencyProps> = ({
  className,
  classNameLabel,
  energyEfficiencyData,
  hasInfo,
  letterSize,
  size,
  ...others
}) => {
  const { eeClass, energyLabel2021Shown, datasheet, label } = energyEfficiencyData;

  const labelRef: RefObject<HTMLDivElement> = useRef(null);
  const toggleRef: MutableRefObject<boolean> = useRef(false);
  const tracking = useTracking(
    {
      className,
      classNameLabel,
      energyEfficiencyData,
      hasInfo,
      letterSize,
      size,
      ...others,
    },
    'EnergyEfficiency',
  );

  const energyClass = eeClass ?? '';

  const accessibilityLabel = useMessage('accessibility.energyEfficiencyLabel', {
    eeClass: energyClass,
  });

  const attributes = {
    letterSize,
    label: energyClass,
    ...others,
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (labelRef.current && toggleRef.current && !isModalOpen) {
      labelRef.current.focus();
    }
  }, [isModalOpen]);

  const toggleModal =
    (toggle: boolean) => (event: MouseEvent | KeyboardEvent | React.KeyboardEvent) => {
      event.stopPropagation();
      toggleRef.current = true;
      tracking(event, { open: toggle });
      setIsModalOpen(toggle);
    };

  const EELabel = energyLabel2021Shown ? EnergyEfficiencyLabel2021 : EnergyEfficiencyLabel;

  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      toggleModal(true)(event);
    }
  };

  const hasDataInformation = !!datasheet || !!label;

  const buttonAttributes = {
    role: 'button',
    tabIndex: 0,
    'aria-pressed': isModalOpen,
    'data-purpose': 'energyEfficiencyLabel.button',
    'aria-label': accessibilityLabel,
    onClick: toggleModal(true),
    onKeyDown: handleKeyDown,
  };

  return (
    <>
      {EELabel.isLabelValid(eeClass ?? '') && (
        <div
          className={classnames(styles.labelWrapper, className)}
          {...(hasDataInformation && buttonAttributes)} // eslint-disable-line react/jsx-props-no-spreading
          ref={labelRef}
        >
          <EELabel
            className={classNameLabel}
            data-purpose={
              energyLabel2021Shown
                ? 'product.energyEfficiencyLabel2021'
                : 'product.energyEfficiencyLabel'
            }
            size={size}
            {...attributes}
          />
          {isModalOpen && (
            <EnergySideSheet
              energyEfficiencyData={energyEfficiencyData}
              hasInfo={hasInfo}
              isModalOpen={isModalOpen}
              toggle={toggleModal}
            />
          )}
        </div>
      )}
    </>
  );
};

export default React.memo(EnergyEfficiency);
