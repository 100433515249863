import React from 'react';
import classnames from 'classnames';
import { matchPath, useRouteMatch } from 'react-router';
import useRoutes from 'hooks/useRoutes';
import CmsContent from 'components/CmsContent';
import ZendeskWidget from 'components/Zendesk/ZendeskWidget';
import FeatureFlag from 'components/FeatureFlag';
import useMandantConfig from 'hooks/useMandantConfig';
import useIsModeHeadless from 'hooks/useIsModeHeadless';
import useIsMobileApp from 'hooks/useIsMobileApp';
import { useExperiment } from '@xxxlgroup/xxxl-a-b-testing';

import styles from 'components/Footer/Footer.scss';
import { useLocation } from 'react-router-dom';

export const getTemplateId = (routes, pathname) => {
  const templatesPages = [
    {
      path: routes.cmsPage,
      templateId: 'singleslotpage',
    },
    {
      path: routes.productDetailPage,
      templateId: 'productdetail',
    },
    {
      path: routes.cart,
      templateId: 'cartpagetemplate',
    },
    {
      path: [routes.checkout.root, routes.reservationPage.root],
      templateId: 'checkout_reservation_template',
    },
    {
      path: routes.categoryPage,
      templateId: 'categorypage',
    },
  ];

  const { templateId } = templatesPages.find(({ path }) => matchPath(pathname, { path })) || {
    templateId: 'singleslotpage',
  };

  return templateId;
};

const Footer = () => {
  const routes = useRoutes();
  const isModeHeadless = useIsModeHeadless();
  const isMobileApp = useIsMobileApp();

  const { variant, variants } = useExperiment('stickyFooterOnPDP');
  const isABTestActive = variant !== variants.default;

  const { pathname } = useLocation();

  const templateId = getTemplateId(routes, pathname);
  const isRestricted = useRouteMatch({
    path: [
      routes.subsidiaryPage.root,
      routes.storeOverview.root,
      '/(c/informationen-zur-aktuellen-lage)',
      routes.homePage,
    ],
  }).isExact;

  const { zenDeskApiKey } = useMandantConfig();
  const isPDP = templateId === 'productdetail';

  if (isModeHeadless || isMobileApp) {
    return null;
  }

  return (
    <footer
      className={classnames(styles.footerWrapper, {
        [styles.extraSpace]: isPDP && !isABTestActive,
        [styles.extraSpaceABTest]: isPDP && isABTestActive,
      })}
    >
      <CmsContent
        className={styles.cmsFooter}
        templateId={templateId}
        templatePosition="template-bottom"
      />
      {!!zenDeskApiKey && (
        <FeatureFlag code="poseidon.contactwidget.enabled">
          <ZendeskWidget isRestricted={isRestricted} zenDeskApiKey={zenDeskApiKey} />
        </FeatureFlag>
      )}
    </footer>
  );
};

export default Footer;
