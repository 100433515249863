import React, { FC, Children, isValidElement } from 'react';
import classnames from 'classnames';

import styles from 'components/Form/components/Grid/Grid.scss';

interface GridProps {
  /** @ignore */
  className?: string;
  /** disables the default margins */
  noMargin?: boolean;
}

const Grid: FC<React.PropsWithChildren<GridProps>> = (props) => {
  const { children, className, noMargin = false } = props;

  const childrenWithProps = Children.map(
    children,
    (child) => isValidElement(child) && React.cloneElement(child, { ...child.props, isGrid: true }),
  );

  return (
    <div className={classnames(className, styles.grid, { [styles.noMargin]: noMargin })}>
      {childrenWithProps}
    </div>
  );
};

export default Grid;
