import React, { ReactNode, useContext, useMemo, useState } from 'react';
import { noop } from '@xxxlgroup/hydra-utils/common';
import type {
  OrderEntry,
  Product,
  PromotionResult,
  UnitBasedCalculation,
} from 'graphql-types/generated/types-generated';
import type { AddToCartErrors, SidebarVariant } from 'pages/ProductDetail/ProductDetails.types';

interface AddToCartSidebarProviderProps {
  children: ReactNode;
}

interface AddToCartSidebarContext {
  sidebarProviderState: {
    isAddToCartSidebarOpen: boolean;
    isUnitBasedSidebar: boolean;
    sidebarData?: {
      product?: Product;
      unitBasedCalculation?: UnitBasedCalculation;
      addedEntry?: OrderEntry;
      cartMessages?: AddToCartErrors;
      potentialOrderPromotions?: PromotionResult[];
      potentialProductPromotions?: PromotionResult[];
      variant: SidebarVariant.CHECKOUT;
      errors?: string[];
    };
  };
  setSidebarProviderState: () => void;
}
const initialSidebarData = {
  product: {
    productCode: '',
    priceData: {},
  },
  unitBasedCalculation: {},
  addedEntry: {},
  cartMessages: [],
  potentialOrderPromotions: [],
  potentialProductPromotions: [],
  variant: 'checkout',
  errors: [],
};
export const initialContext = {
  sidebarProviderState: {
    isAddToCartSidebarOpen: false,
    sidebarData: initialSidebarData,
    isUnitBasedSidebar: false,
  },
  setSidebarProviderState: noop,
};

export const AddToCartSidebarContext = React.createContext(initialContext);

export const useAddToCartSidebarContext = () => useContext(AddToCartSidebarContext);

const AddToCartSidebarProvider = ({ children }: AddToCartSidebarProviderProps) => {
  const [sidebarProviderState, setSidebarProviderState] = useState({
    isAddToCartSidebarOpen: false,
    isUnitBasedSidebar: false,
    sidebarData: initialSidebarData,
  });

  const value = useMemo(
    () => ({
      sidebarProviderState,
      setSidebarProviderState,
    }),
    [sidebarProviderState],
  );

  return (
    <AddToCartSidebarContext.Provider value={value}>{children}</AddToCartSidebarContext.Provider>
  );
};

export default AddToCartSidebarProvider;
