import React, { FC, ReactNode, useMemo } from 'react';
import classNames from 'classnames';
import track from 'react-tracking';
import { Carousel } from '@xxxlgroup/hydra-ui-components';

import { tagComponent } from 'utils/tracking/tracking';
import { useTracking } from 'utils/tracking/hooks';
import { removeDuplicates } from 'utils/common';
import useMessage from 'components/Message/useMessage';
import SkipSection from 'components/SkipSection';
import WithImpressionTracker from 'components/WithImpressionTracker/WithImpressionTracker';
import { v4 as uuidv4 } from 'uuid';

import styles from 'components/ProductSlider/ProductSlider.scss';

export const TYPES = {
  EXTENDED: 'EXTENDED_SLIDER',
  IMAGE: 'SLIDER_WITH_IMAGE',
  LIST: 'LIST',
  REGULAR: 'SLIDER',
} as const;

type SliderKeyTypes = keyof typeof TYPES;
export type SliderTypes = (typeof TYPES)[SliderKeyTypes];

interface ProductSliderProps {
  /** link to an overview page/listing */
  allProductsLink?: ReactNode;
  /** @ignore */
  children: ReactNode[];
  /** @ignore */
  className?: string;
  /** Data for heading */
  heading?: ReactNode;
  /** Title from heading */
  headingTitle?: string;
  /** Unique id for the a11y skipSection */
  id: string;
  /** overlay for the last item in Carousel */
  lastItemOverlay?: ReactNode;
  /** Style of the slider */
  representationStyle?: SliderTypes;
}

const ProductSlider: FC<ProductSliderProps> = (props) => {
  const { EXTENDED, REGULAR } = TYPES;
  const {
    allProductsLink = null,
    children,
    className = null,
    heading = null,
    headingTitle = '',
    id,
    lastItemOverlay = null,
    representationStyle = REGULAR,
  } = props;

  const tracking = useTracking(props, 'ProductSlider');

  const [goTo, next, previous, sectionName] = useMessage(
    [
      'wxs.slider.goto',
      'wxs.slider.arrow.nextPicture',
      'wxs.slider.arrow.previousPicture',
      'accessibility.skipSection.list',
    ],
    {},
    true,
  );

  const onSlideChanged = (oldIndex: number, newIndex: number, event?: Event) => {
    tracking(event, { newIndex, oldIndex });
  };

  const uniqueChildren = useMemo(
    () =>
      removeDuplicates(children, 'key').map((tile: ReactNode, index: number) => {
        if (lastItemOverlay && children && children.length - 1 === index) {
          return (
            <div className={styles.overlay} key={uuidv4()}>
              {tile}
              {lastItemOverlay}
            </div>
          );
        }
        return tile;
      }),
    [children, lastItemOverlay],
  );

  const renderSlider = () => (
    <Carousel
      animationSpeed="fast"
      autoPlay={false}
      className={classNames(styles.carousel, {
        [styles.extended]: representationStyle === EXTENDED,
      })}
      data-extended={representationStyle === EXTENDED}
      data-purpose="product.imageSlider"
      i18n={{
        next,
        previous,
        goTo,
      }}
      onSlideChanged={onSlideChanged}
      sneakPeekDisabled={false}
    >
      {uniqueChildren}
    </Carousel>
  );

  return (
    <div
      className={classNames(className, {
        [styles.noHeading]: !heading,
      })}
    >
      <div className={styles.headingContainer}>
        {heading}
        {allProductsLink}
      </div>
      <SkipSection title={headingTitle} id={id} section={sectionName}>
        {renderSlider()}
      </SkipSection>
    </div>
  );
};

export default track(tagComponent('ProductSlider'))(WithImpressionTracker(ProductSlider as FC));
