import { gql } from '@apollo/client';

const breadcrumbs = `itemCode name restType seoUrl type`;

const pageData = `uid code name restType title`;

const seoData = `canonicalUrl
code
description
keywords
name
noFollow
noIndex
url
title
seoPath
baseStoreRelatedItems {
  locale
  url
}`;

export const CMSCONTENT_PAGE_QUERY = gql`
  query contentPages($code: String!, $cmsTicketId: String, $keepCache: String, $filters: String) {
    isInSmartEdit @client
    cmsTicketId @client @export(as: "cmsTicketId")
    keepCache @client @export(as: "keepCache")
    getContentPage(code: $code, cmsTicketId: $cmsTicketId, keepCache: $keepCache, filters: $filters) {
      ${pageData}
      seoData {
        ${seoData}
      }
      cmsPageType {
        code
        type
        name
        cmsPageTypeSubtype
      }
      breadcrumbs {
       ${breadcrumbs}
      }
      smartedit {
        pageContract
      }
      contentSlots
    }
  }
`;

export const TEMPLATE_PAGE_QUERY = gql`
  query contentTemplate($templateId: String!, $position: String!, $cmsTicketId: String) {
    isInSmartEdit @client
    cmsTicketId @client @export(as: "cmsTicketId")
    getTemplatePage(templateId: $templateId, position: $position, cmsTicketId: $cmsTicketId) {
      contentSlots
    }
  }
`;

export const CMSCONTENT_PRODUCT_QUERY = gql`
  query productContent($code: String!, $cmsTicketId: String) {
    cmsTicketId @client @export(as: "cmsTicketId")
    getProductContent(code: $code, cmsTicketId: $cmsTicketId) {
      components
      restType
    }
  }
`;

export const CMSCONTENT_PRODUCT_SPECIFIC_QUERY = gql`
  query productSpecificContent($code: String!, $pageCode: String!, $cmsTicketId: String) {
    isInSmartEdit @client
    cmsTicketId @client @export(as: "cmsTicketId")
    getProductSpecificContent(code: $code, pageCode: $pageCode, cmsTicketId: $cmsTicketId) {
      contentSlots
    }
  }
`;
