import React, { useEffect, useState, useMemo, useRef, FC } from 'react';
import track from 'react-tracking';
import classnames from 'classnames';
import { circlePlus, cogs, color, hardness, size, information } from '@xxxlgroup/hydra-icons';
import { ProductCard, ProductCardVariant, Rating } from '@xxxlgroup/hydra-ui-components';
import loadable, { LoadableComponent } from '@loadable/component';
import { useExperiment } from '@xxxlgroup/xxxl-a-b-testing';
import ProductContext, { ProductContextType } from 'context/ProductDetail.context';

import { tagComponent } from 'utils/tracking/tracking';
import { useImpressionTracker } from 'utils/tracking/hooks';
import { isProductStatusOnlyInStore } from 'utils/product/misc';
import useFeatureFlagsEnabled from 'hooks/useFeatureFlagsEnabled';
import { pseudoIcon } from '@xxxlgroup/hydra-utils/icon';

import Link from 'components/WebshopLink';
import useMessage from 'components/Message/useMessage';
import EnergyEfficiencyLink from 'components/EnergyEfficiency/components/EnergyEfficiencyLink/EnergyEfficiencyLink';
import Labels from 'components/ProductTile/components/Labels';
import ProductPrice from 'components/ProductPrice';
import useProductTileVariants, {
  ProductVariant,
} from 'components/ProductTile/hooks/useProductTileVariants';

import { getProductUrl } from 'utils/url';
import { noop } from '@xxxlgroup/hydra-utils/common';
import { parseHydraImage } from 'utils/imageUtils';
import { getColorVariants, getMediaDataPresentation } from 'components/ProductTile/utils';
import { TYPES } from 'components/ProductSlider/ProductSlider';

import type { ColorVariantData, ProductTileProps } from 'components/ProductTile/ProductTile.types';
import type { ExtendedActionsProps } from 'components/ProductTile/components/ExtendedActions/ExtendedActions.types';

import styles from 'components/ProductTile/ProductTile.scss';

const ExtendedActions: LoadableComponent<ExtendedActionsProps> = loadable(
  () => import('components/ProductTile/components/ExtendedActions/ExtendedActions') as any,
  { ssr: true },
);

const ProductTile: FC<ProductTileProps> = (props) => {
  const { EXTENDED } = TYPES;
  const {
    adInfo,
    availabilityStatus,
    averageRating = 0,
    brands,
    className,
    code,
    configurable,
    'data-page': dataPage,
    'data-purpose': dataPurpose,
    energyEfficiencyData,
    eyecatchers,
    freeDelivery,
    hasRatings,
    hideFavourite,
    hotdealData,
    imageSizes,
    isProductGrid,
    isSponsored,
    mediaData,
    name,
    numberOfReviews,
    onClick = noop,
    orderData,
    pageRef,
    peeriusId,
    priceData,
    representationStyle,
    tileRef,
    url,
    variantContainerData,
  } = props;

  const { datasheets, presentation } = mediaData || {};
  const [iconAfterStyle, iconAfterClassName] = pseudoIcon(information, 'before');
  const EMPTY_IMAGE_OBJECT = {};

  const isDense = representationStyle === EXTENDED;

  const { variant: productTileExperiment, variants: productTileExperiments } =
    useExperiment('productTileTest');

  const isAddToCartEnabled =
    useFeatureFlagsEnabled('poseidon.productTile.extended') &&
    productTileExperiment === productTileExperiments.enabled &&
    !isDense;

  const isEligibleForAddToCart = !!isAddToCartEnabled && !!orderData?.buyable;
  const productVariant = configurable ? 'configurable' : variantContainerData?.variantHighlightType;
  const icon = productVariant
    ? {
        hardness,
        color,
        configurable: cogs,
        multioptions: circlePlus,
        size,
      }[productVariant]
    : undefined;

  const currentPrice = priceData?.currentPrice?.value;

  const { setRef, tracking } = useImpressionTracker(
    { ...props, price: currentPrice },
    'ProductTile',
  );

  const [availableInStore, freeDeliveryInfo, variantMobile, variantDesktop, sponsored] = useMessage(
    [
      'product.tile.availableInStore',
      'product.tile.freeDelivery',
      `wxs.productTile.variants.${productVariant}.mobile`,
      `wxs.productTile.variants.${productVariant}.desktop`,
      'poseidon.retail.media.sponsored',
    ],
  );

  const i18nVariants = useMemo(
    () => ({ variantMobile, variantDesktop }),
    [variantMobile, variantDesktop],
  );

  const isFavouritesEnabled = !!(useFeatureFlagsEnabled('poseidon.favourites') && !hideFavourite);

  const colorVariants = useMemo(
    () => getColorVariants(variantContainerData),
    [variantContainerData],
  );

  const mediaDataInfo = useMemo(
    () => getMediaDataPresentation(colorVariants.variantValues, presentation),
    [colorVariants, presentation],
  );

  const images = mediaDataInfo?.filter((media) => media.fileType === 'IMAGE');
  const mainImage = images[0];
  const hoverImage = images.length > 1 ? images[1] : null;
  const defaultProductVariant: ProductVariant = {
    availabilityStatus,
    priceData,
    name,
    url,
    code,
  };

  const [currentDisplayedProduct, setCurrentDisplayedProduct] =
    useState<ProductVariant>(defaultProductVariant);

  const articleRef = useRef(null);

  useEffect(() => {
    setCurrentDisplayedProduct({ priceData, name, url, code });
  }, [code, name, priceData, url]);

  const handleTracking = (event: Event) => {
    (tracking as (event: any, data?: NonNullable<unknown>) => void)(event);
  };

  const handleMouseLeave = (event: Event) => {
    (tracking as (event: any, data?: NonNullable<unknown>) => void)(event);
    setCurrentDisplayedProduct({ priceData, name, url, code, availabilityStatus });
  };

  const productContext = useMemo(
    () => ({
      product: { ...props, ...currentDisplayedProduct } as ProductContextType,
      loading: false,
    }),
    [props, currentDisplayedProduct],
  );

  const tileAttributes = {
    'data-page': dataPage,
    'data-purpose': dataPurpose,
    ref: pageRef,
    onBlur: handleTracking,
    onFocus: handleTracking,
    onMouseEnter: handleTracking,
    onMouseLeave: handleMouseLeave,
  };

  const trackingProps = {
    buttonType: 'productTileButtonSmall',
    code: code?.valueOf(),
    configurable: configurable?.valueOf(),
    quantity: 1,
    selectedDeliveryMode: 'delivery',
    selectedQuantity: 1,
  };

  const hotdealAssignedStoreName = hotdealData?.assignedStore?.name;
  const shouldShowPrice = !!currentPrice;
  const brandNames = brands?.map((brand) => brand.name || '');

  const linkAttributes = {
    'data-purpose': 'productTile.link.product',
    'data-track-id': isProductGrid ? 'product' : 'associatedProduct',
    'data-product-id': code,
    href: currentDisplayedProduct.url || '',
    onClick,
    peeriusId, // this data is absolutely needed for tracking and must stay here
    name,
    price: currentPrice,
  };

  const labelsAttributes = {
    styles,
    isDense,
    eyecatchers,
    savedPercent: priceData?.savedPercent,
    energyEfficiencyData,
    isFavouritesEnabled: isFavouritesEnabled && !isAddToCartEnabled,
    className,
    code: code || '',
  };

  const colorVariantGroupsExtended = colorVariants?.variantValues?.map((variantValue) => ({
    image: variantValue.productPicture,
    productUrl:
      variantValue.product?.url ||
      (variantValue.product?.code && getProductUrl(variantValue.product?.code)),
  })) as ColorVariantData[];

  const variants = useProductTileVariants({
    currentDisplayedProduct: defaultProductVariant,
    colorVariants,
    styles,
    variants: colorVariantGroupsExtended,
    setCurrentDisplayedProduct,
  });

  return (
    <div ref={setRef}>
      <ProductCard
        {...tileAttributes}
        additionalContent={
          <div className={styles.subHeader}>
            {!isDense && (
              <>
                <ProductCardVariant i18n={i18nVariants} glyph={icon} />
                <EnergyEfficiencyLink
                  className={styles.energyEfficiencyLink}
                  datasheets={datasheets}
                />
              </>
            )}
            {hasRatings && !isDense && (
              <div className={styles.ratings}>
                <Rating className={styles.rating} size="xsmall" value={Number(averageRating) / 5} />
                <span className={styles.count}>({numberOfReviews})</span>
              </div>
            )}
          </div>
        }
        className={classnames(styles.productTile, className, {
          'sponsored-product': adInfo?.adId,
        })}
        isDense={isDense}
        imageSizes={imageSizes}
        ref={dataPage ? tileRef : articleRef}
        subTitle={brandNames}
        title={name || ''}
        link={<Link {...linkAttributes} />}
        labels={Array(<Labels key={code} {...labelsAttributes} />)}
        imageData={parseHydraImage(mainImage) ?? EMPTY_IMAGE_OBJECT}
        hoverImageData={parseHydraImage(hoverImage) ?? undefined}
        variants={!isDense ? variants : []}
        variantsClassName={styles.optionWrapper}
      >
        {isAddToCartEnabled ? (
          <ProductContext.Provider value={productContext}>
            <ExtendedActions
              isEligibleForAddToCart={isEligibleForAddToCart}
              isFavouritesEnabled={isFavouritesEnabled}
              addToCartDataPurpose="product.tile.cart.button.small"
              trackingProps={trackingProps}
            />
          </ProductContext.Provider>
        ) : (
          <>
            {shouldShowPrice && (
              <ProductPrice
                className={styles.price}
                priceData={currentDisplayedProduct.priceData as any}
              />
            )}
            {isProductStatusOnlyInStore(
              currentDisplayedProduct?.availabilityStatus || availabilityStatus,
            ) &&
              !hotdealAssignedStoreName && (
                <div className={styles.storeOnly}>{availableInStore}</div>
              )}
            {freeDelivery && <div className={styles.freeDelivery}>{freeDeliveryInfo}</div>}
            {hotdealAssignedStoreName && (
              <div className={styles.hotdealWrapper}>{hotdealAssignedStoreName}</div>
            )}
          </>
        )}
        {isSponsored && (
          <div className={classnames(styles.sponsored, iconAfterClassName)} style={iconAfterStyle}>
            {sponsored}
          </div>
        )}
      </ProductCard>
    </div>
  );
};

export default track(tagComponent('ProductTile'))(ProductTile);
