/**
 * Checks the status of one or more FeatureFlags.
 * @param {[Object]} featureFlags Array of FeatureFlag objects.
 * @param {String | [String]} codes One or more FeatureFlags.
 * @returns {[Boolean]} An Array where each value represents the enabled state of the corresponding FeatureFlag.
 */
interface FeatureFlagStructure {
  code: string;
  enabled: boolean;
}
const getFeatureFlags = (featureFlags: FeatureFlagStructure[], codes: string | string[]) =>
  (Array.isArray(codes) ? codes : [codes]).map(
    (code) => featureFlags?.find((feature) => feature.code === code)?.enabled || false,
  );

export default getFeatureFlags;
