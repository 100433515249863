import { ZendeskAPI } from 'react-zendesk';

const KONTAKT = 'Kontakt';
const KONTAKT_BUTTON = 'Kontakt-Aufnahme';
const KONTAKT_FORM = 'Kontaktformular';

interface Properties {
  id?: string;
  name?: string;
}

interface UserEvent {
  action: string;
  category?: string;
  properties?: Properties;
}

const resetWidget = (event: UserEvent) => {
  if (event?.properties?.id === '360001543560') {
    ZendeskAPI('webWidget', 'close');
    ZendeskAPI('webWidget', 'reset');
    window.open('https://www.poco.de/kontakt-allgemeine-beanstandungsaufnahme');
  }
};

const overwritePocoLink = (iframeDocument: Document) => {
  const aTags = iframeDocument.getElementsByTagName('div');
  const searchText = 'Hier finden Sie unsere Datenschutzerklärung. (https://www.poco.de/c/privacy)';
  const found = [...aTags].filter((item) => item.textContent === searchText)[0];
  if (found !== undefined) {
    found.innerHTML = 'Hier finden Sie unsere ';
    const a = iframeDocument.createElement('a');
    const linkText = iframeDocument.createTextNode('Datenschutzerklärung');
    a.appendChild(linkText);
    a.title = 'Datenschutzerklärung';
    a.href = 'https://www.poco.de/c/privacy';
    a.setAttribute('target', '_blank');
    found.appendChild(a);
  }
};

const handleWidget = (event: UserEvent) => {
  const iframe: HTMLIFrameElement = document.getElementById('webWidget') as HTMLIFrameElement;
  const iframeDocument = iframe?.contentDocument || iframe?.contentWindow?.document;
  if (iframeDocument) {
    overwritePocoLink(iframeDocument);
  }
  resetWidget(event);
};

export const handleFormEvent = (event: UserEvent) => {
  if (event.action === 'Contact Form Shown') {
    handleWidget(event);
  }
};

export const handleZendeskLoaded = () => {
  if (ZendeskAPI) {
    ZendeskAPI('webWidget', 'setLocale', 'de');
    ZendeskAPI('webWidget:on', 'userEvent', handleFormEvent);
  }
};

export const webWidget = {
  offset: {
    horizontal: '0px',
    vertical: '100px',
  },
  contactOptions: {
    enabled: true,
    contactFormLabel: { '*': KONTAKT_FORM },
    chatLabelOffline: { '*': 'Chat zur Zeit nicht verfügbar' },
    contactButton: { '*': KONTAKT_BUTTON },
  },
  helpCenter: {
    originalArticleButton: false,
    chatButton: { '*': KONTAKT },
    contactButton: { '*': KONTAKT_BUTTON },
  },
  launcher: {
    label: { '*': KONTAKT },
    chatLabel: { '*': KONTAKT },
  },
  contactForm: {
    title: {
      '*': KONTAKT_FORM,
    },
    selectTicketForm: {
      '*': 'Bitte wählen Sie Ihr Anliegen:',
    },
    fields: [
      {
        id: 'subject',
        prefill: { '*': 'Anfrage über Kontaktformular' },
      },
    ],
    attachments: false,
    ticketForms: [
      {
        id: 360001543420,
        subject: false,
        title: false,
        fields: [
          {
            id: 'subject',
            prefill: {
              '*': 'Anfrage Auftrag/Bestellung',
            },
          },
        ],
        attachments: true,
      },
      {
        id: 360001543560,
        subject: false,
        title: false,
        fields: [
          {
            id: 'subject',
            prefill: {
              '*': 'Beanstandung',
            },
          },
        ],
        attachments: true,
      },
      {
        id: 360001527339,
        subject: false,
        title: false,
        fields: [
          {
            id: 'subject',
            prefill: {
              '*': 'Allgemeine Anfrage',
            },
          },
        ],
        attachments: true,
      },
    ],
  },
};
