import React, { useCallback, MouseEventHandler, useEffect, useState } from 'react';
import classnames from 'classnames';
import track from 'react-tracking';

import useMessage from 'components/Message/useMessage';
import useFeatureFlags from 'hooks/useFeatureFlags';
import { cameraDSLR } from '@xxxlgroup/hydra-icons';
import ModalView from 'pages/ImageSearch/components/ModalView';
import { local } from '@xxxlgroup/hydra-utils/storage';
import { Modal, IconButton } from '@xxxlgroup/hydra-ui-components';
import { useImageSearchContext } from 'pages/ImageSearch/ImageSearch.provider';
import { useTracking } from 'utils/tracking/hooks';
import { tagComponent } from 'utils/tracking/tracking';

import {
  SEARCH_BAR_IMAGE_FLYOUT_INTERACTION_PURPOSE,
  SEARCH_BAR_IMAGE_INTERACTION_PURPOSE,
} from 'pages/ImageSearch/ImageSearch.state';
import { actions } from 'pages/ImageSearch/ImageSearch.types';

import styles from 'components/Header/components/ImageSearchButton/ImageSearchButton.scss';

export const IMAGE_SEARCH_BUTTON_CLICKED = 'imageSearchButtonClicked';
export const IMAGE_SEARCH_PROMOTE_ENABLED = 'poseidon.searchBar.imageSearch.promoteIcon.enabled';
export const IMAGE_SEARCH_TOOLTIP_DISPLAYED = 'imageSearchTooltipDisplayed';

export interface ImageSearchButtonProps {
  closeTooltip?: () => void;
  handleHover?: MouseEventHandler<HTMLButtonElement>;
  /** render modal */
  withModal?: boolean;
}

const ImageSearchButton = (props: ImageSearchButtonProps) => {
  const tracking = useTracking(props, 'ImageSearchButton');
  const { closeTooltip, handleHover, withModal } = props;

  const { dispatch, state } = useImageSearchContext();
  const { SHOW_MODAL, HIDE_MODAL } = actions;

  const [heading, close] = useMessage(['imagesearch.heading', 'wxs.modal.closeButton.ariaLabel']);
  const [promoteImageSearchFlag] = useFeatureFlags([IMAGE_SEARCH_PROMOTE_ENABLED]);
  const [shouldPromoteImageSearch, setShouldPromoteImageSearch] = useState(promoteImageSearchFlag);

  useEffect(() => {
    if (promoteImageSearchFlag) {
      setShouldPromoteImageSearch(!local.getItem(IMAGE_SEARCH_BUTTON_CLICKED));
    }
  }, [promoteImageSearchFlag]);

  const handleClick = useCallback(
    (event: React.MouseEvent) => {
      dispatch({ type: SHOW_MODAL });
      closeTooltip?.();

      local.setItem(IMAGE_SEARCH_BUTTON_CLICKED, true);
      local.setItem(IMAGE_SEARCH_TOOLTIP_DISPLAYED, true);

      tracking(event, {
        type: 'imageSearch',
        event: { type: 'click', purpose: SEARCH_BAR_IMAGE_INTERACTION_PURPOSE },
        props: { action: 'SearchBar-Tooltip' },
      });
    },
    [SHOW_MODAL, closeTooltip, dispatch, tracking],
  );

  const handleCloseModal = useCallback(
    (event: KeyboardEvent | React.MouseEvent) => {
      tracking(event, {
        type: 'imageSearch',
        event: { type: 'click', purpose: SEARCH_BAR_IMAGE_FLYOUT_INTERACTION_PURPOSE },
        props: { action: 'Flyout::Close' },
      });

      dispatch({ type: HIDE_MODAL });
    },
    [HIDE_MODAL, dispatch, tracking],
  );

  return (
    <div className={styles.iconButtonWrapper}>
      {state?.showModal && withModal && (
        <Modal heading={heading} i18n={{ close }} variant="sidebar" onClose={handleCloseModal}>
          <ModalView />
        </Modal>
      )}
      <IconButton
        ariaLabel={heading}
        className={classnames(styles.cameraButton, {
          [styles.promote]: shouldPromoteImageSearch,
        })}
        data-purpose="image.search.modal.open"
        glyph={cameraDSLR}
        onClick={handleClick}
        onMouseEnter={handleHover}
        onMouseLeave={handleHover}
      />
    </div>
  );
};

export default track(tagComponent('ImageSearchButton'))(ImageSearchButton);
