import React, { type FC, type MouseEvent, useCallback } from 'react';
import track from 'react-tracking';
import * as icons from '@xxxlgroup/hydra-icons';
import { Image as ImageTypes } from '@xxxlgroup/hydra-ui-components/dist/common/types/typeDefinitions';
import { tagComponent } from 'utils/tracking/tracking';
import { useTracking } from 'utils/tracking/hooks';
import { Icon } from '@xxxlgroup/hydra-ui-components';
import Link from 'components/WebshopLink';
import useMessage from 'components/Message/useMessage';
import useRoutes from 'hooks/useRoutes';
import useMandantConfig from 'hooks/useMandantConfig';

interface HomeButtonProps {
  /** classname for the link */
  linkClassName?: string;
  /** icon as SVG or image */
  icon?: ImageTypes;
  /** classname for the icon */
  iconClassName?: string;
  /** data-purpose for tracking information */
  dataPurpose: string;
  /** callback click */
  onClick?: (event: React.PointerEvent<HTMLAnchorElement>) => void;
}

const LogoButton: FC<HomeButtonProps> = (props) => {
  const { linkClassName, icon, iconClassName, dataPurpose, onClick } = props;

  const { poseidonLogoIconName } = useMandantConfig();
  const { logo } = icons;
  const shopLogo = icons[poseidonLogoIconName] ?? logo;

  const logoAriaLabel = useMessage('wxs.logo.ariaLabel', null, true);
  const { homePage } = useRoutes();
  const tracking = useTracking(props, 'LogoButton');

  const handleClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      tracking(event);
    },
    [tracking],
  );

  return (
    <Link
      href={homePage}
      className={linkClassName}
      onClick={onClick || handleClick}
      data-purpose={dataPurpose}
      ariaLabel={logoAriaLabel}
    >
      <Icon aria-hidden="true" glyph={icon ?? shopLogo} className={iconClassName} />
    </Link>
  );
};

export default track(tagComponent('LogoButton'))(LogoButton);
