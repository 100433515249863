import { makeVar } from '@apollo/client';
import { useCallback } from 'react';

export const searchBarDefaults = {
  term: '',
  suggest: false,
};

export const searchBarVar = makeVar({
  term: searchBarDefaults.term,
  suggest: searchBarDefaults.suggest,
});

export const searchBarClientFieldsPolicy = {
  searchBar: {
    read() {
      return searchBarVar();
    },
  },
};

export const useSearchBar = () => {
  const resetSearchTerm = useCallback(() => {
    searchBarVar({ ...searchBarVar(), term: searchBarDefaults.term });
  }, []);

  const updateSearchTerm = useCallback((term) => {
    searchBarVar({ ...searchBarVar(), term });
  }, []);

  const updateSuggestVisibility = useCallback((suggest = true) => {
    searchBarVar({ ...searchBarVar(), suggest });
  }, []);

  return { resetSearchTerm, updateSearchTerm, updateSuggestVisibility };
};
