import React, { FunctionComponent, ReactNode } from 'react';
import classnames from 'classnames';
import { pseudoIcon } from '@xxxlgroup/hydra-utils/icon';
import { check } from '@xxxlgroup/hydra-icons';
import { AnchorType } from 'components/AnchorBar/AnchorBar.types';
import styles from 'components/AnchorBar/components/Dropdown/Dropdown.scss';

interface DropdownProps {
  activeIndex: number;
  anchors: AnchorType[];
  className?: string;
  children: ReactNode;
  firstHiddenIndex: number;
  onSelect: (index: number, url: string) => void;
}

const Dropdown: FunctionComponent<DropdownProps> = ({
  activeIndex,
  anchors,
  className,
  children,
  firstHiddenIndex,
  onSelect,
}) => {
  const [iconAfterStyle, iconAfterClassName] = pseudoIcon(check, 'after');
  return (
    <div className={classnames(styles.wrapper, className)} role="listbox">
      {children}
      <nav className={styles.dropdown}>
        {anchors.map(({ url, linkName }, index) => {
          const actualAnchorIndex = firstHiddenIndex + index;
          const isActive = activeIndex === actualAnchorIndex;
          const handleSelect = () => onSelect(actualAnchorIndex, url);
          return (
            <button
              className={classnames(styles.item, iconAfterClassName, { [styles.active]: isActive })}
              key={url}
              onClick={handleSelect}
              style={iconAfterStyle}
            >
              Anchor {linkName}
            </button>
          );
        })}
      </nav>
    </div>
  );
};

export default Dropdown;
