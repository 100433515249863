import loadable from '@loadable/component';

export default loadable(
  () =>
    import(
      /* webpackChunkName:"cms" */
      /* webpackPreload: true */
      'components/CmsContent/CmsContent'
    ),
);
