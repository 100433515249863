import React, { useEffect, useContext, type FC } from 'react';
import classnames from 'classnames';
import { pseudoIcon } from '@xxxlgroup/hydra-utils/icon';
import { Heading, Button } from '@xxxlgroup/hydra-ui-components';
import { circleExclamationMark } from '@xxxlgroup/hydra-icons';
import Layout from 'components/Layout';
import Message from 'components/Message';
import Link from 'components/WebshopLink';
import SSRContext from 'Providers/SSRContext';
import useRoutes from 'hooks/useRoutes';

import styles from './Error500.scss';

interface Error500Props {
  uid: string | null;
  /** Used to differentiate between error reasons on SSR, where we can not easily see network errors */
  errorReasons?: string[];
}

const Error500: FC<Error500Props> = ({ uid, errorReasons = null }) => {
  const ssrContext = useContext(SSRContext);
  if (ssrContext) {
    ssrContext.statusCode = 500;
    if (errorReasons) {
      // Add to existing error reasons
      errorReasons.forEach((error) => ssrContext.errorReasons.add(error));
    }
  }

  const routes = useRoutes();

  useEffect(() => {
    window.statusCode = 500;
  }, []);

  const [iconStyle, iconClassName] = pseudoIcon(circleExclamationMark);

  return (
    <Layout className={styles.container} variant="narrow">
      <Heading
        SEO="h1"
        level={1}
        data-purpose="error500.heading"
        className={classnames(styles.heading, iconClassName)}
        content={<Message code="wxs.error.500.headline" />}
        /*
        // eslint-disable-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error bug in Heading */
        style={iconStyle}
      />
      <div className={styles.message} data-purpose="error500.content">
        <Message code="wxs.error.500.content" />
      </div>
      <Link
        as={<Button variant="primary" type="button" />}
        href={routes.homePage}
        className={styles.button}
        data-purpose="error500.button.toHome"
      >
        <Message code="wxs.error.500.button.content" />
      </Link>
      <div className={styles.uid}>UID: {uid}</div>
    </Layout>
  );
};

export default Error500;
