import React, { FC, useCallback } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { Content, Icon } from '@xxxlgroup/hydra-ui-components';
import * as icons from '@xxxlgroup/hydra-icons';
import { openCookieModal } from 'utils/openCookieModal';

import styles from 'components/ContentExtended/ContentExtended.scss';

// TO DO: export 'Size' from 'Content' in Hydra and reuse it here
export type ContentSizeProp =
  | 'xxs'
  | 'xxs-emphasized'
  | 'xs'
  | 'xs-bold'
  | 'sm'
  | 'sm-bold'
  | 'rg'
  | 'rg-bold'
  | 'lg'
  | 'lg-bold'
  | 'inherit';

interface ContentExtendedProps {
  className?: string;
  content: string;
  onClick?: (event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLOrSVGElement>) => void;
  size?: ContentSizeProp;
}

const getValidAttributes = (attributes: string): Record<string, any> =>
  attributes
    .split('|')
    .map((pair) => pair.split('='))
    .filter(([key]) => ['glyph', 'role', 'ariaLabel'].includes(key))
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

const ContentExtended: FC<ContentExtendedProps> = (props) => {
  const { content, onClick } = props;
  const parseIcon = (_iconString: string, attributesString: string): string => {
    const attributes = getValidAttributes(attributesString);

    const { glyph } = attributes;

    if (!glyph || !icons[glyph]) {
      return '';
    }

    return renderToStaticMarkup(
      <Icon {...attributes} className={styles.icon} glyph={icons[glyph]} />,
    );
  };

  const parseIcons = (data: string): string => data.replace(/{{icon:(.*?)}}/g, parseIcon);

  const onClickHandler = useCallback(
    (event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLOrSVGElement>) => {
      const { target } = event;
      if (target instanceof HTMLElement) {
        const href = target.getAttribute('href');
        if (href) {
          openCookieModal(event, href);
        }
      }
    },
    [],
  );

  return (
    <>
      {content && (
        <Content {...props} content={parseIcons(content)} onClick={onClick || onClickHandler} />
      )}
    </>
  );
};

export default ContentExtended;
