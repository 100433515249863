import React, { FC, useRef, RefObject, useState, useEffect, CSSProperties, useMemo } from 'react';
import { Loading } from '@xxxlgroup/hydra-ui-components';
import classnames from 'classnames';

import styles from 'components/Iframe/Iframe.scss';

interface IframeProps {
  /** Specifies a feature policy */
  allow?: string;
  /** Sets a class to give customized styles to the list. */
  className?: string;
  /** data-purpose attr for testing purposes */
  dataPurpose?: string;
  /** extra padding for IFrame */
  extraPadding?: number;
  /** fixedHeight */
  fixedHeight?: number;
  /** iFrame id */
  id?: string;
  /** type of the loading */
  loadingType?: 'eager' | 'lazy';
  /** show loading */
  isLoading?: boolean;
  /** loading Callback */
  onLoad?: () => void;
  /** target src for the Iframe */
  targetUrl: string;
  /** title for the Iframe */
  title: string;
  /** class for custom iframeWrapper styles */
  wrapperClassName?: string;
}

const Iframe: FC<IframeProps> = ({
  allow,
  className,
  dataPurpose,
  extraPadding = 50,
  fixedHeight,
  id,
  loadingType = 'eager',
  isLoading = 'true',
  onLoad,
  targetUrl,
  title,
  wrapperClassName,
}) => {
  const [frameHeight, setFrameHeight] = useState(0);
  const [loading, setLoading] = useState(true);

  const iframeRef: RefObject<HTMLIFrameElement> = useRef(null);

  useEffect(() => {
    const iframe = iframeRef?.current;
    iframe?.addEventListener('load', handleLoad);

    if (!fixedHeight) {
      window.addEventListener('message', readMessage);
    }

    return () => {
      iframe?.removeEventListener('load', handleLoad);

      if (!fixedHeight) {
        window.removeEventListener('message', readMessage);
      }
    };
  }, [fixedHeight]);

  useEffect(() => {
    if (!loading && onLoad) {
      onLoad();
    }
  }, [onLoad, loading]);

  const readMessage = (event: MessageEvent) => {
    if (event.data) {
      const message = String(event.data);
      let height;

      /**
        POCO iframes uses the iFrame Resizer library to manage the height of the iframe
        this is to manage the message retrieved from those iframes
      */
      if (message.startsWith('[iFrameSizer]')) {
        [, height] = event.data.split(':');
      } else {
        const heightCheck = /height=(\d+)$/i;
        height = message.match(heightCheck);
        height = height ? height[1] : null;
      }

      if (height) {
        setFrameHeight(parseInt(height, 10));
      }
    }
  };

  const handleLoad = () => {
    setLoading(false);
  };

  const fixedHeightStyle = useMemo(
    () =>
      fixedHeight
        ? ({
            '--height': fixedHeight,
            '--minHeight': fixedHeight,
          } as CSSProperties)
        : {},
    [fixedHeight],
  );

  return (
    <div className={styles.container}>
      {loading && isLoading && (
        <div className={styles.loadingWrapper} style={fixedHeightStyle}>
          <Loading />
        </div>
      )}
      <div className={classnames(styles.iframeWrapper, wrapperClassName)} style={fixedHeightStyle}>
        <iframe
          allow={allow}
          className={className}
          data-purpose={dataPurpose}
          height={fixedHeight || frameHeight + extraPadding}
          frameBorder="0"
          id={id}
          loading={loadingType}
          ref={iframeRef}
          src={targetUrl}
          style={fixedHeightStyle}
          title={title}
          width="100%"
        />
      </div>
    </div>
  );
};

export default Iframe;
