import React, { FC, useCallback, useEffect, useMemo } from 'react';
import track from 'react-tracking';
import classnames from 'classnames';
import { useTracking } from 'utils/tracking/hooks';
import { tagComponent } from 'utils/tracking/tracking';
import { SelectInput } from '@xxxlgroup/hydra-ui-components';
import { asField, FieldApi, FieldState, useField } from 'informed';

import useLanguage from 'hooks/useLanguage';
import useMediaQuery from 'components/MediaQuery/useMediaQuery';
import useMandantConfig from 'hooks/useMandantConfig';

import useMessage from 'components/Message/useMessage';
import styles from 'components/Form/components/FormSelectInput/FormSelectInput.scss';

export interface FormSelectInputProps {
  className?: string;
  /** name of the field (used for the data structure) */
  field: string;
  /** message code for the placeholder code (automatic one will be overwritten) */
  placeholderCode?: string;
  /** A definition for browser which keyboard should be shown on mobile. */
  inputMode?: 'tel';
  fieldState: FieldState<any>;
  fieldApi: FieldApi<any>;
  formPrefix: string;
  onChange?: () => void;
}

interface CountriesWithFlag {
  iso: string;
  name: string;
  prefix: string;
  rank: number;
}

const componentType = 'FormSelectInput';

const FormSelectInput: FC<FormSelectInputProps> = (props) => {
  const { className, field, placeholderCode, fieldState, fieldApi, onChange, ...other } = props;

  const { languageCode } = useLanguage();
  const adaptedLanguageCode = languageCode.toUpperCase();
  const { phoneNumberCountries } = useMandantConfig();
  const isTouch = useMediaQuery({ touchOnly: true });
  const tracking = useTracking(props, componentType);

  const countryListSortedByRankAndAlphabet = useMemo(() => {
    const sortedByAlphabet = [...phoneNumberCountries].sort(
      (a: { name: string }, b: { name: string }) => a.name.localeCompare(b.name),
    );

    return [...sortedByAlphabet].sort((a, b) => {
      if (a.rank === 0) return 1;
      if (b.rank === 0) return -1;
      return b.rank - a.rank;
    });
  }, [phoneNumberCountries]);

  const isoField = useField({
    ...props,
    field: `${other.formPrefix}phoneNumberIsoCode`,
  });

  const hiddenField = useField({
    ...props,
    field: `${other.formPrefix}phoneNumberInternationalHidden`,
  });

  const placeholder = useMessage(placeholderCode || `form.input.${field}.placeholder`);

  const mapPhoneNumbersCountries = (data: CountriesWithFlag[]) =>
    data.map((country: CountriesWithFlag) => ({
      ...country,
      id: country.iso,
    }));

  useEffect(() => {
    // isoField needs to be prefilled with something
    if (isoField.fieldApi.getValue() === undefined) {
      isoField.fieldApi.setValue(adaptedLanguageCode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeSelectInput = useCallback(
    ({ selectValue, inputValue }: { selectValue: string; inputValue: string }) => {
      hiddenField.fieldApi.setValue('');
      isoField.fieldApi.setValue(selectValue);

      fieldApi.setValue(inputValue);
      fieldApi.setTouched(true);

      inputValue && fieldApi.validate();

      const selectedCountry = countryListSortedByRankAndAlphabet.find(
        (obj) => obj.iso === selectValue,
      );

      const position = countryListSortedByRankAndAlphabet.findIndex(
        (obj) => obj.iso === selectValue,
      );

      tracking('onChange', {
        country: selectedCountry?.iso,
        code: selectedCountry?.prefix,
        position: position + 1,
      });

      onChange && onChange();
    },
    [
      countryListSortedByRankAndAlphabet,
      hiddenField.fieldApi,
      isoField.fieldApi,
      fieldApi,
      tracking,
      onChange,
    ],
  );

  const selectValue = useMemo(() => {
    const stateValue = isoField.fieldState.value;
    return stateValue ? String(stateValue) : adaptedLanguageCode;
  }, [adaptedLanguageCode, isoField.fieldState.value]);

  const [phoneNumber, search, searching, mandatory, cancel] = useMessage([
    'formSelectInput.phoneNumber',
    'formSelectInput.search',
    'formSelectInput.searching',
    'formSelectInput.mandatory',
    'formSelectInput.cancel',
  ]);

  return (
    <div className={classnames(className)}>
      <SelectInput
        className={styles.selectInputField}
        i18n={{
          inputLabel: phoneNumber,
          searchInputLabel: search,
          isSearchLabelHidden: true,
          searchInputAriaLabel: search,
          searchInputPlaceholder: searching,
          mandatory,
          cancel,
        }}
        data-purpose={`form.input.${field}`}
        options={mapPhoneNumbersCountries(countryListSortedByRankAndAlphabet)}
        selectDefaultValue={selectValue}
        inputDefaultValue={fieldApi.getValue() ?? ''}
        placeholder={placeholder}
        errors={fieldState.error}
        isTouch={isTouch}
        {...other}
        type="phone"
        onChange={onChangeSelectInput}
      />
    </div>
  );
};

export default asField(track(tagComponent(componentType))(FormSelectInput));
