import React from 'react';
import PropTypes from 'prop-types';
import { Helmet as ReactHelmet } from 'react-helmet-async';
import { sanitize } from 'isomorphic-dompurify';
import {
  linkTags as importedLinkTags,
  metaTags as importedMetaTags,
} from 'assets/theming/favicons';
import { appName, appColorHighlight } from 'assets/theming/appInfo';

import withRedirect from 'components/Helmet/components/withRedirect';

const getLinkTags = (canonicalUrl, hreflangData, noIndex, preloadData) =>
  importedLinkTags(appColorHighlight, [
    {
      rel: 'manifest',
      href: `/assets/site.webmanifest`,
    },
    // only render a canonical url when its provided and when page should be indexed
    // & when on a category page with an allowed facet
    ...(canonicalUrl && !noIndex
      ? [
          {
            rel: 'canonical',
            href: `${canonicalUrl}`,
          },
        ]
      : []),
    ...preloadData,
    // only render alternate urls when data is provided and when page should be indexed
    ...(hreflangData && !noIndex
      ? [
          // go through hreflang data for different stores and create objects with data for each alternate link
          ...hreflangData.map(({ locale, url }) => ({
            rel: 'alternate',
            href: url,
            hreflang: locale === 'rs-RS' ? 'sr-RS' : locale,
          })),
        ]
      : []),
  ]);

const getApplicationMeta = (props) =>
  // TODO: move this to dedicated config file
  importedMetaTags(appColorHighlight, [
    {
      name: 'theme-color',
      content: `${appColorHighlight}`,
    },
    {
      name: 'apple-mobile-web-app-title',
      content: `${appName}`,
    },
    {
      name: 'application-name',
      content: `${appName}`,
    },
    {
      name: 'apple-mobile-web-app-capable',
      content: 'yes',
    },
    {
      name: 'apple-mobile-web-app-status-bar-style',
      content: 'black',
    },
    ...props.metaData,
  ]);

const getPageMeta = (props) => {
  const {
    canonicalUrl,
    description,
    hreflangData,
    keywords,
    noIndex,
    noFollow,
    title,
    preloadData,
  } = props;
  const robotsNoIndex = noIndex ? 'noindex' : 'index';
  const robotsNoFollow = noFollow ? 'nofollow' : 'follow';
  const robotsContent = `${robotsNoIndex}, ${robotsNoFollow}`;
  const linkTags = getLinkTags(canonicalUrl, hreflangData, noIndex, preloadData);
  const metaTags = [
    {
      name: 'robots',
      content: `${robotsContent}`,
    },
    ...getApplicationMeta(props),
  ];
  if (description) {
    metaTags.push({
      name: 'description',
      content: `${description}`,
    });
  }
  if (keywords) {
    metaTags.push({
      name: 'keywords',
      content: `${keywords}`,
    });
  }

  let cleanedTitle = sanitize(title, { ALLOWED_TAGS: [] }).toString();
  // fix ampersand and &nbsp; issue after updating dompurity version, see: https://github.com/cure53/DOMPurify/issues/379
  cleanedTitle = cleanedTitle
    .replace(/&nbsp;/g, ' ')
    .replace(/&amp;/g, '&')
    .replace(/&lt;/g, '<');

  return {
    title: cleanedTitle,
    meta: [...metaTags],
    link: linkTags,
  };
};

const Helmet = (props) => <ReactHelmet {...getPageMeta(props)} />;

Helmet.propTypes = {
  /** Canonical Redirect that enables automatic redirects to a canonical Url */
  withCanonicalRedirect: PropTypes.bool,
  /** canonicalUrl value of the page head */
  canonicalUrl: PropTypes.string,
  /** Description of the page head */
  description: PropTypes.string,
  /** hreflang data for links to shops in different languages */
  hreflangData: PropTypes.arrayOf(
    PropTypes.shape({
      locale: PropTypes.string,
      url: PropTypes.string,
    }),
  ),
  /** Keywords of the page head */
  keywords: PropTypes.string,
  /** Array of objects containing React.Helmet metatags */
  metaData: PropTypes.arrayOf(PropTypes.shape({})),
  /** NoIndex value of the page head */
  noIndex: PropTypes.bool,
  /** NoFollow value of the page head */
  noFollow: PropTypes.bool,
  /** Title of the page head */
  title: PropTypes.string,
  /** Add preload link data which convert to link tags into the page head */
  preloadData: PropTypes.arrayOf(
    PropTypes.shape({
      /** set rel of link tag */
      rel: PropTypes.string,
      /** set href of link tag */
      href: PropTypes.string,
      /** set as attribute of link tag */
      as: PropTypes.string,
      /** set fetchpriority of asset link tag */
      fetchpriority: PropTypes.string,
    }),
  ),
};

Helmet.defaultProps = {
  withCanonicalRedirect: false,
  canonicalUrl: null,
  description: null,
  hreflangData: [],
  keywords: null,
  metaData: [],
  noIndex: false,
  noFollow: false,
  title: null,
  preloadData: [],
};

export default withRedirect(Helmet);
