import React, { FC } from 'react';
import classNames from 'classnames';
import Link from 'components/WebshopLink';
import type { Campaign } from 'graphql-types/generated/types-generated';
import type { CampaignTrackingData } from 'components/Listing/components/ProductTileGrid/components/Campaigns/Campaigns.types';

import styles from 'components/ImageLegalLink/ImageLegalLink.scss';

export interface PromotionTerms {
  /** It can be 'White' or 'Black' only. The background will have an inverted color */
  color: Campaign['promotionTermsColor'];
  link: Campaign['promotionTermsLink'];
  linkTarget: Campaign['promotionTermsLinkOpen'];
  text: Campaign['promotionTermsText'];
}

interface ImageLegalLinkProps extends PromotionTerms, CampaignTrackingData {}

const LinkOpenTargetMap: Record<string, string> = {
  same_tab: '_self',
  new_tab: '_blank',
};

const ImageLegalLink: FC<ImageLegalLinkProps> = ({
  color,
  link,
  linkTarget,
  text,
  ...trackingData
}) => {
  if (!link || !text) {
    return null;
  }

  return (
    <div className={classNames(styles.linkContainer, styles[`linkBackground-${color || 'White'}`])}>
      <Link
        className={styles.link}
        href={link}
        target={LinkOpenTargetMap[linkTarget || 'same_tab']}
        trackingData={trackingData}
      >
        <span className={styles.linkText}>{text}</span>
      </Link>
    </div>
  );
};

export default ImageLegalLink;
