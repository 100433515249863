import type { ReactElement } from 'react';

export enum Breakpoint {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
  xxl = 'xxl',
  xxxl = 'xxxl',
} // has one extra item for "from xxl +1 until infinity"

export interface UseMediaQueryProps {
  largerThan?: Breakpoint;
  smallerThan?: Breakpoint;
  touchOnly?: boolean;
}

export interface MediaQueryProps extends UseMediaQueryProps {
  children: (match: boolean) => ReactElement;
}

export enum PointerType {
  touch = 'touch',
  mouse = 'mouse',
}

export interface DeviceType {
  pointer: PointerType;
}
