import React, { ReactNode } from 'react';
import { JSX } from 'react/jsx-runtime';

interface RootProps {
  children: ReactNode;
  'data-testid'?: string;
  entry: string;
  id?: string;
  mode?: 'create-root' | 'hydrate-root';
  tagName?: keyof JSX.IntrinsicElements;
}

const SSRRoot = ({
  children,
  'data-testid': dataTestId,
  entry,
  id,
  mode = 'create-root',
  tagName: Component = 'div',
}: RootProps) => (
  <Component {...{ [`data-${mode}`]: entry, id, 'data-testid': dataTestId }}>{children}</Component>
);

const SSRHydrateRoot = ({ children }: { children: ReactNode }) => <>{children}</>;

const CSRRoot = ({
  children,
  entry,
  'data-testid': dataTestId,
  id,
  mode = 'create-root',
  tagName: Component = 'div',
  ...attributes
}: RootProps) => (
  <Component id={id} data-testid={dataTestId}>
    {children}
  </Component>
);

const ROOT_FOR_CSR = CONFIG.IS_HYDRATED_SSR ? SSRHydrateRoot : CSRRoot;
export default CONFIG.IS_SSR ? SSRRoot : ROOT_FOR_CSR;
