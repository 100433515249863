import React, { FC, RefObject, ReactElement, ReactNode } from 'react';
import classnames from 'classnames';

import styles from 'components/Layout/Layout.scss';

export type LayoutGridType =
  | 'demoGrid'
  | `grid-${0 | 1 | 2 | 3 | 4 | 5}`
  | 'grid-gallery'
  | 'grid-productdetail-gallery'
  | `grid-teaser-${1 | 2 | 3}`
  | 'productGrid'
  | 'productLarge';

export type tagType = 'article' | 'div' | 'footer' | 'header' | 'section' | 'ul' | 'li';

interface LayoutProps {
  /** anchor ID */
  anchorId?: string;
  /** This is the content in HTML string as it comes e.g. from a database. */
  children?: ReactNode;
  /** Sets a class to give customized styles to the div element. */
  className?: string;
  /** data attribute for testing purposes */
  dataTestId?: string;
  /** Ref of the div element */
  forwardedRef?: RefObject<any>;
  /** The variant specifies the type of grid and adds a css class. */
  grid?: LayoutGridType;
  /** Remove default margins from layout */
  margin?: 'breadcrumbs' | 'breadcrumbsPDS' | 'content' | 'noBreadcrumbs' | 'none' | 'section';
  /** The element that should be used as the outer wrapper of the component */
  tag?: tagType;
  /** The variant specifies the type of layout it is and adds a css class. */
  variant?: 'default' | 'full' | 'narrow';
}

const Layout: FC<LayoutProps> = (props) => {
  const {
    anchorId,
    children,
    className,
    dataTestId,
    forwardedRef,
    grid,
    margin = 'section',
    tag: Tag = 'div',
    variant = 'default',
    ...other
  } = props;

  const { restType, representationType } = (children as ReactElement)?.props || {};
  const isProductCellList =
    restType === 'CmsProductCellContainer' &&
    !['SLIDER', 'SLIDER_WITH_IMAGE', 'EXTENDED_SLIDER'].includes(representationType);

  const classNames = classnames(
    styles[variant],
    styles[`${margin}Margin`],
    grid && styles[grid],
    isProductCellList && styles.nestedFullWidthGrid,
    className,
  );
  const contentAttributes = {
    id: anchorId,
    children,
    className: classNames,
    ...(forwardedRef && { ref: forwardedRef }),
    ...other,
  };

  return <Tag data-testid={dataTestId} {...contentAttributes} />;
};

export default Layout;
