import React, { FC } from 'react';
import { RadioGroup } from '@xxxlgroup/hydra-ui-components';
import classnames from 'classnames';
import { asField, FieldApi, FieldState } from 'informed';
import track from 'react-tracking';
import useMessage from 'components/Message/useMessage';
import { useTracking } from 'utils/tracking/hooks';
import { tagComponent } from 'utils/tracking/tracking';

import styles from 'components/Form/components/FormRadioGroup/FormRadioGroup.scss';

export interface FormRadioGroupProps {
  /** react children */
  children: React.ReactElement | React.ReactElement[];
  /** className for external styling */
  className?: string;
  /** value of preselected radio */
  defaultValue?: string;
  /** name of the field  */
  field: string;
  /** @see FormTypes#fieldApi */
  fieldApi: FieldApi;
  /** @see FormTypes#fieldState */
  fieldState: FieldState<any>;
  /** @deprecated used because <Field /> relies conditionally on forwardRef */
  forwardedRef?: React.RefObject<any>;
  /** Toggle whether the aria-label should be hidden */
  hideAriaLabel?: boolean;
  /** message code for the label code (automatic one will be overwritten) */
  labelCode?: string;
  /** Set a name for the radio group. *Note:* required for correct browser behaviour. Otherwise the operation by keyboard does not work as expected. */
  name: string;
  /** onChange handler function */
  onChange?: (id: string) => void;
  /** The field is mandatory and must be filled. *Notice:* An asterisk is automatically appended to the label/title. */
  required?: boolean;
}

const FormRadioGroup: FC<FormRadioGroupProps> = (props) => {
  const tracking = useTracking(props, 'FormRadioGroup');
  const {
    children,
    className,
    defaultValue,
    field,
    fieldApi: { setValue },
    fieldState: { value, error },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    forwardedRef: _,
    hideAriaLabel,
    labelCode,
    name,
    onChange,
    required,
    ...other
  } = props;
  const label = useMessage(labelCode || `form.radiogroup.${field}.label`);

  const handleChange = (id: string, event: React.ChangeEvent<HTMLInputElement>) => {
    tracking(event);
    setValue(id);
    if (onChange) {
      onChange(id);
    }
  };

  return (
    <div className={classnames(className, styles.checkBox)}>
      <RadioGroup
        aria-label={!hideAriaLabel ? label : undefined}
        data-purpose={`form.radiogroup.${field}`}
        defaultValue={defaultValue || value}
        name={name}
        onChange={handleChange}
        required={!!required}
        {...other}
      >
        {children as React.ReactElement}
      </RadioGroup>
      {error && (
        <div className={styles.error} data-purpose="form.radiogroup.error">
          {error}
        </div>
      )}
    </div>
  );
};

export default track(tagComponent('FormRadioGroup'))(asField(FormRadioGroup));
