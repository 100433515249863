import React, { type FC, useCallback } from 'react';
import Error500 from 'components/ErrorHandler/components/Error500';
import Error404 from 'components/ErrorHandler/components/Error404';
import { useHistory } from 'react-router-dom';
import { type ApolloError } from '@apollo/client';
import { type GraphQLErrors } from '@apollo/client/errors';

export const ERROR_REASONS = {
  404: {
    cc_catchall: '404_cc_catchall',
    status_nosearch: '404_status_nosearch',
    response: '404_response',
    statusCode_search: '400_statusCode_search',
    flagNotFound: '404_flagNotFound',
    ca_catchall: '404_ca_catchall',
    customercard_catchall: '404_customercard_catchall',
    catchAll: '404_catchAll',
    kitchenadvisor_catchall: '404_kitchenadvisor_catchall',
  },
  500: {
    graphqlStatus: '500_graphqlStatus',
    statusCode: '500_statusCode',
    networkError: '500_networkError',
  },
};

interface ErrorHandlerProps {
  /** graphql error object */
  error?: ApolloError | null;
  /** error code */
  statusCode?: number;
  /** uid for logging */
  uid?: string;
}

const ErrorHandler: FC<ErrorHandlerProps> = ({ error = null, statusCode = 404, uid = '' }) => {
  const evaluateGraphQlErrors = useCallback(
    (graphQlErrors: GraphQLErrors, externalStatusCode: number, searchTerm: string) => {
      let graphQLStatusCode: any = null;
      let graphQLUid = null;
      let graphQlPath = null;
      let graphQlCode = null;

      graphQlErrors.every((graphQLError) => {
        if (!graphQLStatusCode) {
          graphQLStatusCode = graphQLError.extensions?.statusCode;
          graphQLUid = graphQLError.extensions?.uid;
          graphQlPath = graphQLError.path;
          graphQlCode = graphQLError.extensions?.code;
        }

        return !graphQLStatusCode;
      });

      if (
        String(graphQLStatusCode).startsWith('5') ||
        (graphQlCode === 'INTERNAL_SERVER_ERROR' && !graphQLStatusCode)
      ) {
        return (
          <Error500
            errorReasons={[`${ERROR_REASONS[500].graphqlStatus}:${graphQlPath}:${graphQLUid}`]}
            uid={graphQLUid}
          />
        );
      }

      if (externalStatusCode === 404 && !searchTerm) {
        return (
          <Error404
            errorReason={`${ERROR_REASONS[404].status_nosearch}:${graphQlPath}:${graphQLUid}`}
          />
        );
      }

      if (externalStatusCode === 404 || graphQLStatusCode === 404) {
        return (
          <Error404
            errorReason={`${ERROR_REASONS[404].response}:${graphQlPath}:${graphQLUid}`}
            searchTerm={searchTerm}
          />
        );
      }

      return null;
    },
    [],
  );

  const history = useHistory();

  const params = new URLSearchParams(history.location.search);
  const searchTerm = params.get('s') || '';

  if (!error && Math.floor(statusCode / 100) === 4) {
    return <Error404 errorReason={ERROR_REASONS[404].statusCode_search} searchTerm={searchTerm} />;
  }

  // TODO: remove this code when everything is refactored to graphql
  if (!error && Math.floor(statusCode / 100) === 5) {
    return <Error500 errorReasons={[ERROR_REASONS[500].statusCode]} uid={uid} />;
  }

  // If we have a network error, show a 500 on SSR
  if (error && error.networkError) {
    return (
      <Error500
        errorReasons={[ERROR_REASONS[500].networkError, JSON.stringify(error.networkError)]}
        uid={uid}
      />
    );
  }

  if (error && error.graphQLErrors && error.graphQLErrors.length > 0) {
    const evaluatedError = evaluateGraphQlErrors(error.graphQLErrors, statusCode, searchTerm);

    if (evaluatedError !== null) {
      return evaluatedError;
    }
  }

  return null;
};

export default ErrorHandler;
