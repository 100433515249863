import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Redirect } from 'react-router';

const getPathnameFromUrl = (url) => {
  try {
    return new URL(url)?.pathname;
  } catch (error) {
    // if it is not a valid url, return the value
    return url;
  }
};

const executeRedirect = (history, canonicalUrl, withCanonicalRedirect) => {
  if (canonicalUrl && withCanonicalRedirect) {
    const parsedCanonicalURL = getPathnameFromUrl(canonicalUrl);
    const historyPathname = history?.location?.pathname;

    if (historyPathname && parsedCanonicalURL !== false && historyPathname !== parsedCanonicalURL) {
      return (
        <Redirect
          to={{
            ...history.location,
            pathname: parsedCanonicalURL,
            search: history.location.search,
            state: { redirect: true },
          }}
        />
      );
    }
  }
  return null;
};

const RedirectWrapper = (props) => {
  const history = useHistory();

  const { withCanonicalRedirect, canonicalUrl, component: Component } = props;

  const redirect = executeRedirect(history, canonicalUrl, withCanonicalRedirect);

  return redirect || <Component {...props} />;
};

RedirectWrapper.propTypes = {
  /** Canonical Redirect that enables automatic redirects to a canonical Url */
  withCanonicalRedirect: PropTypes.bool,
  /** canonicalUrl value of the page head */
  canonicalUrl: PropTypes.string,
  /** node to be rendered if no redirect */
  component: PropTypes.elementType,
};

RedirectWrapper.defaultProps = {
  withCanonicalRedirect: false,
  canonicalUrl: '',
};

const withRedirect = (Component) => (props) => <RedirectWrapper {...props} component={Component} />;

export default withRedirect;
