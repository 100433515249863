import React from 'react';
import PropTypes from 'prop-types';
import useLanguage from 'hooks/useLanguage';
import {
  PriceCompound,
  PriceCurrent,
  PriceOld,
  PricePerUnit,
} from '@xxxlgroup/hydra-ui-components';
import { PriceTypes } from 'types';
import { getCurrentPriceUnitProp, useSingleUnitProps } from 'utils/product/price/unit';
import {
  useOldPriceMessage,
  checkIfOldPriceIsStrike,
  useCheckIfOldPriceIsShown,
  getAsteriskCount,
} from 'utils/product/price/oldPrice';

const ProductPrice = ({ priceData, className }) => {
  const { currencyFormat } = useLanguage();
  const { currentPrice, oldPrice, productUnitDiffersToPriceUnit } = priceData || {};
  const currentPriceValue = currentPrice?.value;
  const oldPriceValue = oldPrice?.value;

  const asteriskCount = getAsteriskCount(currentPrice);
  const oldPriceMessage = useOldPriceMessage(asteriskCount, oldPrice);
  const unitProps = useSingleUnitProps(priceData);
  const areCurrentAndOldPriceSame = currentPriceValue === oldPriceValue;
  const showOldPrice = useCheckIfOldPriceIsShown(
    oldPriceValue,
    areCurrentAndOldPriceSame,
    priceData?.hideUvp,
  );
  const isOldPriceStrike = checkIfOldPriceIsStrike(asteriskCount);

  return (
    <PriceCompound className={className} currencyFormat={currencyFormat} size="small">
      {showOldPrice && (
        <PriceOld
          asteriskCount={asteriskCount}
          data-purpose="product.price.old"
          value={oldPriceMessage}
          strikeThrough={isOldPriceStrike}
        />
      )}
      <PriceCurrent
        data-purpose="product.price.current"
        data-track-id="priceContainer"
        value={currentPriceValue}
        discounted={!!oldPriceValue}
        unit={getCurrentPriceUnitProp(priceData)}
      />
      {!productUnitDiffersToPriceUnit && unitProps && (
        <PricePerUnit {...unitProps} data-purpose="product.price.unit" />
      )}
    </PriceCompound>
  );
};

ProductPrice.propTypes = {
  className: PropTypes.string,
  priceData: PriceTypes.priceDataProps.isRequired,
};

ProductPrice.defaultProps = {
  className: null,
};
export default ProductPrice;
