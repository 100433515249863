import React, { FC, ReactNode } from 'react';
import classnames from 'classnames';
import useMessage from 'components/Message/useMessage';

import styles from 'components/SkipSection/SkipSection.scss';

type SpacingOptions = 'normal' | 'small' | 'large';

interface SkipSectionProps {
  /** Boolean to activate or deactivate a link */
  active?: boolean;
  /** @ignore */
  children: ReactNode;
  /** data attribute for testing purposes */
  dataTestId?: string;
  /** ID to identify the correct section */
  id: string;
  /** Name of the wrapped component */
  section: string;
  /** defines top position of the link */
  spacing?: SpacingOptions;
  /** title from the wrapped component */
  title?: string;
}

const SkipSection: FC<SkipSectionProps> = ({
  active = true,
  children,
  dataTestId = null,
  id,
  section,
  spacing = 'normal',
  title = '',
}) => {
  const anchor = `skip-section-${section}-${id}`;

  const skipSection = useMessage('accessibility.skipSection');
  const [screenReaderMessage, shortScreenReaderMessage] = useMessage(
    [
      'accessibility.skipSection.screenReader.message', // 'Jump over the next {section} with the {title}'
      'accessibility.skipSection.screenReader.message.short', // 'Jump over the next {section}']
    ],
    { section, title },
    true,
  );

  const renderLink = () => (
    <>
      <a
        aria-label={title ? screenReaderMessage : shortScreenReaderMessage}
        className={classnames(styles.skipLink, {
          [styles.large]: spacing === 'large',
          [styles.small]: spacing === 'small',
        })}
        href={`#${anchor}`}
        data-testid={dataTestId}
      >
        {skipSection}
      </a>
      {children}
      <div id={anchor} />
    </>
  );

  return active ? renderLink() : children;
};

export default SkipSection;
