import { gql } from '@apollo/client';
import { features } from 'pages/App/components/ApplicationDataProvider/Preload.query';

export const FORM_FIELD_QUERY = gql`
  query getFeatures($context: String) {
    getFeatures(context: $context) {
      ${features}
    }
  }
`;
