import React, { FC, ReactNode } from 'react';
import { useImpressionTracker } from 'utils/tracking/hooks';

type WithImpressionTrackerProps = {
  isPromotion?: boolean;
  promotionToggle?: boolean;
};
type componentProps = { [key: string]: unknown };
type componentPropsWithChildren<P> = P & { children?: ReactNode };

const WithImpressionTracker = CONFIG.IS_BOT_SSR
  ? (Component: React.ElementType) =>
      (props: componentPropsWithChildren<componentProps & WithImpressionTrackerProps>) => {
        const { children, ...restProps } = props;
        return <Component {...restProps}>{children}</Component>;
      }
  : (Component: FC, display = 'block') =>
      (props: componentProps) => {
        const { isPromotion } = props;
        const wrappedComponentName =
          props.restType || Component.displayName || Component.name || 'Component';
        const displayName = `withImpressionTracker(${wrappedComponentName})`;
        const { setRef } = useImpressionTracker(props, displayName);

        /** do not render impression tracker when component isPromotion or promotionToggle is set specifically to false */
        if (isPromotion === false) {
          return <Component {...props} />;
        }
        return (
          <div ref={setRef} style={display !== 'block' ? { display } : undefined}>
            <Component {...props} />
          </div>
        );
      };

export default WithImpressionTracker;
