import React, { FC, useCallback } from 'react';
import classNames from 'classnames';

import * as icons from '@xxxlgroup/hydra-icons';
import { Image } from '@xxxlgroup/hydra-ui-components';
import { Image as ImageTypes } from '@xxxlgroup/hydra-ui-components/dist/common/types/typeDefinitions';

import { pseudoIcon } from '@xxxlgroup/hydra-utils/icon';

import Link from 'components/WebshopLink';
import { useTracking } from 'utils/tracking/hooks';
import styles from 'components/Service/components/ServiceLink/ServiceLink.scss';

const ICON_VARIANT = 'icon-variant';

interface ServiceLinkProps {
  /** Icon name of each item */
  iconName?: string;
  /** Icon itself as SVG or image */
  icon?: ImageTypes;
  /** Name of icon */
  name: string;
  /** link of service */
  url: string;
  /** color of icon */
  iconColor?: string;
  /** if it is list variant or not */
  isListVariant?: boolean;
  /** if it is in promotion or not */
  isPromotion?: boolean;
  /** opens within same or new tab */
  target: string;
}

const ServiceLink: FC<ServiceLinkProps> = (props) => {
  const { iconName, icon, name, url, iconColor, isListVariant, isPromotion, target } = props;

  const tracking = useTracking(props, 'ServiceLink');

  const handleClick = useCallback(
    (serviceName: string) => (event: React.MouseEvent | React.KeyboardEvent) => {
      tracking(event, { serviceName });
    },
    [tracking],
  );

  const [iconStyle, iconClassName] = (iconName && pseudoIcon(icons[iconName])) || [null, null];

  return (
    <li key={`${name}-${icon?.url ?? iconName}`}>
      <Link
        className={classNames(styles.link, {
          [styles['link-variant']]: isListVariant,
        })}
        href={url}
        isPromotion={isPromotion}
        key={`${name}-${icon?.url ?? iconName}`}
        target={target}
        onClick={handleClick(name)}
      >
        {iconName && (
          <span
            className={classNames(styles.icon, iconClassName, styles[`iconColor-${iconColor}`], {
              [styles[ICON_VARIANT]]: isListVariant,
            })}
            style={{ ...iconStyle }}
          />
        )}
        {icon && (
          <Image
            isDecorative
            source={icon}
            className={classNames(styles.icon, {
              [styles[ICON_VARIANT]]: isListVariant,
            })}
          />
        )}
        {name}
      </Link>
    </li>
  );
};

export default ServiceLink;
