import { Product } from 'graphql-types/generated/types-generated';
import {
  CmsImageComponentWsDTO,
  CmsTextImageComponentWsDTO,
} from 'components/Listing/components/ProductTileGrid/components/Campaigns/Campaigns.types';
import { ListingPaginationData, SearchResultsData } from 'components/Listing/types/Listing.types';

type ProductData = SearchResultsData | CmsTextImageComponentWsDTO | CmsImageComponentWsDTO;
export const isProduct = (item: ProductData): item is Product => item.type === 'productWsDTO';
export const isHotDealsType = (pathname: string) => /\/hs/g.test(pathname);

export const getPaginationInfo = (
  userPage: number,
  totalResults: number,
  itemsPerPage: number,
  offsetAdjustment: number,
): ListingPaginationData => {
  const highestPage = Math.ceil((totalResults || 1) / itemsPerPage);
  return {
    currentPage: userPage,
    highestPage,
    lastPageLoaded: userPage >= highestPage,
    offsetAdjustment,
    totalResults,
  };
};
