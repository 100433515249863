import React, { FC, ReactNode } from 'react';
import useFormFeatureFlags from 'components/Form/hooks/useFormFeatureFlags';

import ErrorBoundary from 'components/ErrorBoundary';

interface FieldFlagProps {
  /** Children component to be rendered */
  children: (required: boolean) => ReactNode;
  /** Feature flag code of the wrapped component e.g. 'wxs.newsletter' */
  code: string;
  /** Fallback if flag is disabled */
  fallback?: ReactNode;
}

const FieldFlag: FC<FieldFlagProps> = ({ children, fallback, code }) => {
  const { required, visible } = useFormFeatureFlags(code);
  return visible ? <ErrorBoundary withStatusCode>{children(required)}</ErrorBoundary> : fallback;
};

export default FieldFlag;
