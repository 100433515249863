import React from 'react';
import PropTypes from 'prop-types';
import { Router } from 'react-router-dom';
import DynamicContentProvider from 'Providers/DynamicContentProvider';
import { ApolloProvider } from '@apollo/client';
import { ReactTypes } from 'types';
import { useTracking } from 'react-tracking';
import { ApolloClient } from '@apollo/client/core';
import { HelmetProvider } from 'react-helmet-async';
import ExperimentStatsigProvider from 'pages/App/components/ApplicationDataProvider/ExperimentStatsigProvider';
import CountryContext from 'Providers/IntlProvider/CountryContext';
import VisitorInformationContext from 'services/VisitorInformationService/VisitorInformationContext';
import LoggerContext from 'Providers/LoggerContext';
import I18nContext from 'Providers/I18nContext';
import DynamicContentService from 'services/DynamicContentService';
import ContainerBase from './Container.base';

const ContainerClient = ({
  apolloClient,
  children,
  country,
  dispatch,
  dynamicContentService,
  history,
  i18n,
  logger,
  visitor,
}) => {
  const { Track } = useTracking({}, { dispatch });
  return (
    <HelmetProvider>
      <Track>
        <VisitorInformationContext.Provider value={visitor}>
          <LoggerContext.Provider value={logger}>
            <I18nContext.Provider value={i18n}>
              <CountryContext.Provider value={country}>
                <ApolloProvider client={apolloClient}>
                  <Router history={history}>
                    <DynamicContentProvider dynamicContentService={dynamicContentService}>
                      <ContainerBase>
                        <ExperimentStatsigProvider visitorId={visitor?.visitor?.id}>
                          {children}
                        </ExperimentStatsigProvider>
                      </ContainerBase>
                    </DynamicContentProvider>
                  </Router>
                </ApolloProvider>
              </CountryContext.Provider>
            </I18nContext.Provider>
          </LoggerContext.Provider>
        </VisitorInformationContext.Provider>
      </Track>
    </HelmetProvider>
  );
};

ContainerClient.propTypes = {
  apolloClient: PropTypes.instanceOf(ApolloClient).isRequired,
  dynamicContentService: PropTypes.instanceOf(DynamicContentService),
  dispatch: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  history: ReactTypes.history.isRequired,
  logger: PropTypes.func.isRequired,
  country: PropTypes.shape({
    language: PropTypes.shape({
      languageCode: PropTypes.string,
    }),
  }).isRequired,
  i18n: PropTypes.shape({}).isRequired,
  visitor: PropTypes.shape({
    visitor: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
};

export default ContainerClient;
