import React, { createRef, type MouseEvent, useCallback, useMemo } from 'react';
import classnames from 'classnames';
import track from 'react-tracking';
import { useLocation, withRouter, Link as RouterLink } from 'react-router-dom';
import componentTransformer from '@xxxlgroup/hydra-utils/componentTransformer';
import { WebshopLinkProps } from 'components/WebshopLink/WebshopLink.types';
import { tagComponent } from 'utils/tracking/tracking';
import { useTracking } from 'utils/tracking/hooks';

const componentTransformerOptions = {
  overwriteProps(compProps: Record<string, any>, asProps: Record<string, any>) {
    const { className: compClassName, noFollow, ...restComp } = compProps;
    const { className: asClassName, ...finalAs } = asProps;
    const finalCompProps = {
      ...restComp,
      type: null,
      className: classnames(compClassName, asClassName),
      rel: noFollow ? 'nofollow' : undefined,
    };

    return [finalCompProps, finalAs] as [Record<string, any>, Record<string, any>];
  },
};

const createToObj = (href: string) => {
  const hrefHashParts = href.split('#');
  return hrefHashParts.length === 2
    ? { pathname: hrefHashParts[0], hash: hrefHashParts[1] }
    : { pathname: href };
};

const WebshopLink = (props: WebshopLinkProps) => {
  const {
    ariaLabel,
    ariaHidden = false,
    ariaCurrent,
    ariaHasPopup,
    as = null,
    children,
    className,
    href,
    inert,
    isForceSelfTarget = false,
    linkRef = createRef(),
    noFollow = false,
    onBlur,
    onClick,
    onFocus,
    onKeyDown,
    onMouseEnter,
    onMouseLeave,
    onTouchEnd,
    withPreservedScrollPosition = false,
    withReplace = false,
    state,
    style,
    tabIndex,
    target = '_self',
    title,
    trackingData = {},
    'data-purpose': dataPurpose,
    'data-track-id': dataTrackId,
    'data-product-id': dataProductId,
  } = props;

  const { pathname, search, hash } = useLocation();
  const tracking = useTracking(props, 'WebshopLink');

  const INERT = inert ? '' : undefined;

  const handleClick = useCallback(
    (event: MouseEvent<HTMLAnchorElement>) => {
      const fullPath = `${pathname}${search}${hash}`;
      if (fullPath === href) {
        event.preventDefault();
      }
      tracking(event, trackingData);
      onClick?.(event);
    },
    [onClick, tracking, trackingData, pathname, search, hash, href],
  );

  const routerLink = useMemo(() => {
    const linkToObj = createToObj(href ?? '');
    const position = withPreservedScrollPosition || state ? { ...linkToObj, state } : href;
    const rel = noFollow ? 'nofollow' : undefined;

    const linkAttributes = {
      'aria-current': ariaCurrent,
      'aria-haspopup': ariaHasPopup,
      'aria-hidden': ariaHidden,
      'aria-label': ariaLabel,
      'data-preserve-scroll': withPreservedScrollPosition || null,
      'data-product-id': dataProductId,
      'data-purpose': dataPurpose,
      'data-track-id': dataTrackId,
      className,
      inert: INERT,
      innerRef: linkRef,
      onBlur,
      onClick: handleClick,
      onFocus,
      onKeyDown,
      onMouseEnter,
      onMouseLeave,
      onTouchEnd,
      rel,
      replace: withReplace ?? document.location.pathname === linkToObj.pathname,
      style,
      tabIndex,
      title,
      to: position,
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return <RouterLink {...linkAttributes}>{children}</RouterLink>;
  }, [
    ariaCurrent,
    ariaHasPopup,
    ariaHidden,
    ariaLabel,
    children,
    className,
    dataProductId,
    dataPurpose,
    dataTrackId,
    handleClick,
    href,
    INERT,
    linkRef,
    noFollow,
    onBlur,
    onFocus,
    onKeyDown,
    onMouseEnter,
    onMouseLeave,
    onTouchEnd,
    state,
    style,
    tabIndex,
    title,
    withPreservedScrollPosition,
    withReplace,
  ]);

  const externalLink = useMemo(
    () => (
      <a
        aria-current={ariaCurrent}
        aria-haspopup={ariaHasPopup}
        aria-label={ariaLabel}
        aria-hidden={ariaHidden}
        className={className}
        data-purpose={dataPurpose}
        data-track-id={dataTrackId}
        href={href}
        inert={INERT}
        onBlur={onBlur}
        onClick={handleClick}
        onFocus={onFocus}
        onTouchEnd={onTouchEnd}
        ref={linkRef}
        rel={noFollow ? 'noreferrer noopener nofollow' : 'noreferrer noopener'}
        style={style}
        tabIndex={tabIndex}
        target={isForceSelfTarget ? '_self' : target}
        title={title}
      >
        {children}
      </a>
    ),
    [
      ariaCurrent,
      ariaHasPopup,
      ariaHidden,
      ariaLabel,
      children,
      className,
      dataPurpose,
      dataTrackId,
      handleClick,
      href,
      INERT,
      isForceSelfTarget,
      linkRef,
      noFollow,
      onBlur,
      onFocus,
      onTouchEnd,
      style,
      tabIndex,
      target,
      title,
    ],
  );

  if (href) {
    const isPhoneNumberLink = href?.startsWith('tel:');
    const link = target === '_self' && !isPhoneNumberLink ? routerLink : externalLink;

    return componentTransformer(as || 'div', link, componentTransformerOptions);
  }

  const validHtmlAttributesForDIV = {
    children,
    className,
    'data-product-id': dataProductId,
    'data-purpose': dataPurpose,
    'data-track-id': dataTrackId,
    inert: inert ? ('' as const) : undefined,
    onBlur,
    onClick,
    onFocus,
    onKeyDown,
    onMouseEnter,
    onMouseLeave,
    onTouchEnd,
    style,
    tabIndex,
    title,
  };

  return as
    ? // eslint-disable-next-line react/jsx-props-no-spreading
      componentTransformer(as, <div {...validHtmlAttributesForDIV} />, componentTransformerOptions)
    : null;
};

export default track(tagComponent('Link'))(withRouter(WebshopLink)) as any;
