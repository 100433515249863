// eslint-disable-next-line max-classes-per-file
import { config } from '@xxxlgroup/hydra-config';
import React, {
  type FC,
  type PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { DeviceType, PointerType, Breakpoint } from 'components/MediaQuery/MediaQuery.types';

type MediaQueryProviderProps = { currentBreakpoint: Breakpoint; device: DeviceType };

const defaultState = {
  currentBreakpoint: Breakpoint.sm,
  device: { pointer: PointerType.touch },
};

export const MediaQueryContext = React.createContext<MediaQueryProviderProps>(defaultState);

const breakpointOrder: Breakpoint[] = [
  Breakpoint.xs,
  Breakpoint.sm,
  Breakpoint.md,
  Breakpoint.lg,
  Breakpoint.xl,
  Breakpoint.xxl,
];

export const BaseMediaQueryProvider: FC<PropsWithChildren> = ({ children }) => (
  <MediaQueryContext.Provider value={defaultState}>{children}</MediaQueryContext.Provider>
);

const queries: { query: MediaQueryList; listener: (event: MediaQueryListEvent) => void }[] = [];

export const MediaQueryProvider: FC<PropsWithChildren> = ({ children }) => {
  // Due to how hydration works, we need to present exactly the same initial state to CSR React
  // as the server did. The SSR assumes mobile because we don't know the screen width. Hence
  // on hydration we also assume mobile initially and then change state in onMount
  const [currentBreakpoint, setCurrentBreakpoint] = useState<Breakpoint>(
    defaultState.currentBreakpoint,
  );
  const [pointer, setPointer] = useState<PointerType>(defaultState.device.pointer);

  const responsiveQueryListener = (breakpoint: Breakpoint) => (event: MediaQueryListEvent) => {
    if (!event.matches) {
      return;
    }
    setCurrentBreakpoint(breakpoint);
  };

  const checkScreensizes = useCallback(() => {
    // care about xxl up
    setCurrentBreakpoint(Breakpoint.xxxl);

    const maxQuery = window.matchMedia(
      `(min-width: ${config.breakpoints[breakpointOrder[breakpointOrder.length - 1]]}px)`,
    );

    const maxListener = responsiveQueryListener(Breakpoint.xxxl);
    maxQuery.addEventListener('change', maxListener);
    queries.push({ query: maxQuery, listener: maxListener });

    // care about all regular ranges
    Object.entries(config.breakpoints).forEach(([breakpoint, value]) => {
      const matchQuery =
        config.breakpoints[
          breakpointOrder[breakpointOrder.indexOf(breakpoint as Breakpoint) - 1]
        ] || 0;

      const query = window.matchMedia(
        `(min-width: ${matchQuery}px) and (max-width: ${value - 1}px)`,
      );

      const listener = responsiveQueryListener(breakpoint as Breakpoint);

      if (maxQuery.matches || query.matches) {
        setCurrentBreakpoint(breakpoint as Breakpoint);
      }

      query.addEventListener('change', listener);
      queries.push({ query, listener });
    });
  }, []);

  const checkInputMechanism = useCallback(() => {
    const touchScreenQuery = window.matchMedia(`(pointer: coarse)`);

    if (touchScreenQuery.matches) {
      setPointer(PointerType.touch);
    } else {
      setPointer(PointerType.mouse);
    }

    const touchScreenListener = (event: MediaQueryListEvent) => {
      if (event.matches) {
        setPointer(PointerType.touch);
      } else {
        setPointer(PointerType.mouse);
      }
    };

    touchScreenQuery.addEventListener('change', touchScreenListener);
    queries.push({ query: touchScreenQuery, listener: touchScreenListener });
  }, []);

  useEffect(() => {
    checkScreensizes();
    checkInputMechanism();

    return () => {
      queries.forEach(({ query, listener }) => query.removeEventListener('change', listener));
    };
  }, [checkInputMechanism, checkScreensizes]);

  const contextValue = useMemo(
    () => ({
      currentBreakpoint,
      device: { pointer },
    }),
    [currentBreakpoint, pointer],
  );

  return <MediaQueryContext.Provider value={contextValue}>{children}</MediaQueryContext.Provider>;
};

export default CONFIG.IS_SSR ? BaseMediaQueryProvider : MediaQueryProvider;
