import React from 'react';

import ErrorBoundary from 'components/ErrorBoundary';
import useFeatureFlagsEnabled from 'hooks/useFeatureFlagsEnabled';

/**
 * Resolves mandant specific feature configuration for one or more features.
 * By default, it evaluates to <code>true</code> only if all given features are evaluated truthy.
 */
export interface FeatureFlagProps {
  /** @ignore */
  children?: React.ReactNode;
  /** Feature flag code of the wrapped component e.g. 'wxs.newsletter' or an array ['poseidon.checkout', 'checkout'] */
  code: string | string[];
  /** do not trigger fallback as long as the featureFlags are loading */
  isDeferWhileLoading?: boolean;
  /** Fallback if flag is disabled */
  fallback?: React.ReactNode;
  /** The logical condition for multiple codes.
   * If 'some', at least one of the codes has to be true.
   * If 'every', every code has to be true.
   */
  condition?: 'every' | 'some';
}

const FeatureFlag = (props: FeatureFlagProps) => {
  const { children, code, fallback, isDeferWhileLoading, condition = 'every' } = props;
  const enabledFeature = useFeatureFlagsEnabled(code, { fallbackValue: null, condition });

  if (!isDeferWhileLoading || enabledFeature !== null) {
    return <ErrorBoundary withStatusCode>{enabledFeature ? children : fallback}</ErrorBoundary>;
  }

  return null;
};

export default FeatureFlag;
