import React, {
  ChangeEvent,
  ChangeEventHandler,
  FC,
  FocusEvent,
  FocusEventHandler,
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import track from 'react-tracking';
import classnames from 'classnames';
import { tagComponent } from 'utils/tracking/tracking';
import { useField } from 'informed';
import { Content, Input } from '@xxxlgroup/hydra-ui-components';
import { isValuePresent } from '@xxxlgroup/hydra-utils/common';
import Message from 'components/Message';
import MediaQuery from 'components/MediaQuery';
import { removeEmojis } from 'utils/formatting';
import { useTracking } from 'utils/tracking/hooks';

import styles from 'components/Form/components/FormInput/FormInput.scss';

export type FormInputValue = string | number;

export interface FormInputI18n {
  hidePassword?: string;
  showPassword?: string;
}

export interface FormInputFormatter {
  exec: (value: FormInputValue) => FormInputValue;
  runOnBlur?: boolean;
  runOnChange?: boolean;
  runOnKeyDown?: boolean;
}

export interface FormInputProps {
  /** className for external styling */
  className?: string;
  /** name of the field (used for the data structure) */
  field: string;
  /** formatters */
  formatters?: FormInputFormatter[];
  i18n?: FormInputI18n;
  /** A definition for browser which keyboard should be shown on mobile. */
  inputMode?: 'text' | 'search' | 'decimal' | 'email' | 'none' | 'numeric' | 'tel' | 'url';
  /** message code for the label code (automatic one will be overwritten) */
  labelCode?: string;
  /** onBlur handler function */
  onBlur?: FocusEventHandler<Element>;
  /** onChange handler function */
  onChange?: ChangeEventHandler<Element>;
  /** symbol or text which is shown if the field is optional (not required). */
  optionalSymbol?: string;
  /** flag whether the optionalSymbol will be shown */
  withOptionalSymbol?: boolean;
  /** message code for the placeholder code (automatic one will be overwritten) */
  placeholderCode?: string;
  /** type of input-field. text/multiline */
  type?: string;
  /** max char count for input field */
  maxCharCount?: number;
}

const ALLOWED_MAX_CHAR_FIELDS = [
  'paymentAddress.addition1',
  'deliveryAddress.addition1',
  'userComment',
];

const COMPONENT_NAME = 'FormInput';

const MAX_CHAR_COUNT = 34;

/** applies all formatters with a certain flag(e.g. runOnChange, runOnBlur) */
const applyFormatters = (
  value: FormInputValue,
  formatters: FormInputFormatter[],
  filterFlags: (keyof FormInputFormatter)[],
) =>
  formatters
    .filter((formatter) => filterFlags.some((filterFlag) => formatter[filterFlag]))
    .reduce((currentValue, formatter) => formatter.exec(currentValue), value);

const FormInput: FC<FormInputProps> = (props) => {
  const {
    className,
    onChange,
    onBlur,
    labelCode,
    placeholderCode,
    inputMode = 'text',
    field,
    optionalSymbol = 'wxs.form.optional',
    withOptionalSymbol = false,
    i18n = {
      showPassword: 'Show password',
      hidePassword: 'Hide password',
    },
    formatters = [],
    maxCharCount = MAX_CHAR_COUNT,
    ...other
  } = props;

  const { fieldApi, fieldState } = useField(props);
  const { error } = fieldState;

  const tracking = useTracking(props, COMPONENT_NAME);

  const fieldRestriction = useMemo(() => ALLOWED_MAX_CHAR_FIELDS.includes(field), [field]);

  const [infoAddressLength, setInfoAddressLength] = useState(0);
  const [isMaxLengthReached, setIsMaxLengthReached] = useState(false);
  const [isInfoAddressShown, setIsInfoAddressShown] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const [timeoutId, setTimeoutId] = useState(0);

  useEffect(
    () => () => {
      clearTimeout(timeoutId);
    },
    [timeoutId],
  );

  const value = useMemo(
    () =>
      isValuePresent(fieldState.value as FormInputValue)
        ? (fieldState.value as FormInputValue)
        : '',
    [fieldState.value],
  );

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, trimmedValue: string) => {
      tracking(event);
      const { setValue } = fieldApi;
      const { error } = fieldState;
      const { name } = event.target;
      const formatted = applyFormatters(
        trimmedValue,
        [removeEmojis, ...formatters],
        ['runOnChange'],
      );

      if (ALLOWED_MAX_CHAR_FIELDS.includes(name)) {
        if (trimmedValue.length <= maxCharCount) {
          setValue(formatted);
        }

        setIsInfoAddressShown(true);
        setInfoAddressLength(trimmedValue.length);
      } else {
        setValue(formatted);
      }

      error && setIsTouched(true);

      if (onChange) {
        onChange(event);
      }
    },
    [fieldApi, fieldState, formatters, maxCharCount, onChange, tracking],
  );

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<Element>) => {
      const { value, name } = event.target as EventTarget & HTMLInputElement;
      const { type } = props;
      const { setValue } = fieldApi;

      if (event.key === 'Enter') {
        tracking(event);

        // for normal input fields we execute onblur formatters by pressing enter, in multi-area fields not (enter is a line break)
        setValue(
          applyFormatters(
            value,
            formatters,
            type === 'text' ? ['runOnBlur', 'runOnKeyDown'] : ['runOnKeyDown'],
          ),
        );
      }

      if (ALLOWED_MAX_CHAR_FIELDS.includes(name)) {
        if (value.length === maxCharCount) {
          setIsMaxLengthReached(true);
          setTimeoutId(
            window.setTimeout(() => {
              setIsMaxLengthReached(false);
            }, 1000),
          );
        }

        if (timeoutId && event.key === 'Backspace') {
          clearTimeout(timeoutId);
          setIsMaxLengthReached(false);
        }
      }
    },
    [fieldApi, formatters, maxCharCount, props, timeoutId, tracking],
  );

  const handleBlur = useCallback(
    (event: FocusEvent<Element, Element>) => {
      tracking(event);
      const { value, name } = event.target as EventTarget & HTMLInputElement;
      const { setTouched, setValue } = fieldApi;
      const { error } = fieldState;

      if (!error || isTouched) {
        setTouched(true);
        setIsTouched(false);
      }

      setValue(applyFormatters(value, [removeEmojis, ...formatters], ['runOnBlur']));

      if (onBlur) {
        onBlur(event);
      }

      if (ALLOWED_MAX_CHAR_FIELDS.includes(name)) {
        setIsInfoAddressShown(false);
      }
    },
    [fieldApi, fieldState, formatters, isTouched, onBlur, tracking],
  );

  const handleFocus = useCallback(
    (event: FocusEvent<Element, Element>) => {
      tracking(event);
      const { value, name } = event.target as EventTarget & HTMLInputElement;
      if (ALLOWED_MAX_CHAR_FIELDS.includes(name)) {
        setIsInfoAddressShown(true);
        value?.length && setInfoAddressLength(value.length);
      }
    },
    [tracking],
  );

  const getCharsLength = useCallback(() => {
    const charactersAvailable = maxCharCount - infoAddressLength;

    return charactersAvailable >= 0 ? charactersAvailable : 0;
  }, [infoAddressLength, maxCharCount]);

  return (
    <Message
      code={[
        labelCode || `form.input.${field}.label`,
        placeholderCode || `form.input.${field}.placeholder`,
        'wxs.input.cancel',
        'form.input.length.info.message',
        optionalSymbol,
      ]}
      values={{ length: getCharsLength(), maxCharCount }}
    >
      {([label, placeholder, cancel, infoLength, optionalSymbolText]) => (
        <div className={className}>
          <MediaQuery touchOnly>
            {(isTouch: boolean) => (
              <>
                <Input
                  aria-describedby={isInfoAddressShown ? `${field}_${maxCharCount}` : ''}
                  className={styles.formInput}
                  errors={error}
                  data-purpose={`form.input.${field}`}
                  hideLabel={false}
                  label={label}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  onChange={handleChange as unknown as (event: Event, trimmedValue: string) => void}
                  onKeyPress={handleKeyDown} // onKeyPress is deprecated by React, but still in favor over onKeyDown in Hydra
                  optionalSymbol={withOptionalSymbol ? optionalSymbolText : undefined}
                  placeholder={placeholder}
                  value={value}
                  inputMode={inputMode}
                  isTouch={isTouch}
                  i18n={{ cancel, ...i18n }}
                  maxLength={fieldRestriction ? maxCharCount : undefined}
                  {...other}
                />
                {isInfoAddressShown && (
                  <Content
                    className={classnames(styles.infoAddress, {
                      [styles.reachMax]: isMaxLengthReached,
                    })}
                    content={infoLength}
                    aria-live="polite"
                    id={`${field}_${maxCharCount}`}
                    role={isMaxLengthReached ? 'alert' : 'status'}
                  />
                )}
              </>
            )}
          </MediaQuery>
        </div>
      )}
    </Message>
  );
};

export default track(tagComponent(COMPONENT_NAME))(FormInput);
