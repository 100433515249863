import React, { useEffect, useMemo } from 'react';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import ComponentMapper from 'cms/ComponentMapper';
import { RECOMMENDATIONS_SEEN } from 'components/PeeriusRecos/PeeriusRecos.query';
import { parseProducts } from 'cms/CmsProductCellContainer/utils';
import useDynamicContentService from 'hooks/useDynamicContentService';

const PeeriusRecos = ({ area, className, componentOverwrites, minShown, ...rest }) => {
  const { slots } = useDynamicContentService();
  const components = useMemo(() => {
    const slot = slots.find((item) => item.code === area);
    return slot?.components || [];
  }, [area, slots]);

  const [addProductsSeen] = useMutation(RECOMMENDATIONS_SEEN);
  const productsArray = useMemo(() => components?.[0]?.products || [], [components]);

  useEffect(() => {
    const products = productsArray.map((product) => product?.peeriusId.toString());

    if (products.length > 0) {
      addProductsSeen({
        variables: {
          seenProducts: products,
        },
      });
    }
  }, [components, productsArray, addProductsSeen]);

  const renderSlot = (component) => {
    const productId = component.products?.[0]?.id;
    return (
      <ComponentMapper
        key={`cm-${component.restType}-${productId}`}
        content={{
          ...component,
          products: parseProducts(component.products, null, area, 'PEERIUS'),
          ...rest,
          ...componentOverwrites,
        }}
      />
    );
  };

  return components.length > 0 && productsArray.length >= minShown ? (
    <div data-purpose={area} className={className}>
      {components.map(renderSlot)}
    </div>
  ) : null;
};

PeeriusRecos.propTypes = {
  /** className of the content slot */
  className: PropTypes.string,
  /** Type of content slot component */
  area: PropTypes.string.isRequired,
  /** Explicit values overwrite for PeeriusRecos component props */
  componentOverwrites: PropTypes.shape({}),
  /** minimal amount of products required to show the Peerius slider */
  minShown: PropTypes.number,
};

PeeriusRecos.defaultProps = {
  className: null,
  componentOverwrites: {},
};

export default PeeriusRecos;
