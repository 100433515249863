import React, { type FC, PropsWithChildren, Children, isValidElement } from 'react';
import classnames from 'classnames';

import styles from 'components/Form/components/Block/Block.scss';

interface BlockProps {
  /** @ignore */
  className?: string;
  /** disables the default margins */
  noMargin?: boolean;
}

const Block: FC<PropsWithChildren<BlockProps>> = (props) => {
  const { children, className, noMargin = false } = props;

  const childrenWithProps = Children.map(children, (child) => {
    if (isValidElement(child)) {
      if (child.type === 'div') {
        return React.cloneElement(child, { ...child.props });
      }

      return React.cloneElement(child, { ...child.props, isBlock: true });
    }

    return null;
  });

  return (
    <div className={classnames(styles.block, { [styles.noMargin]: noMargin }, className)}>
      {childrenWithProps}
    </div>
  );
};

export default Block;
