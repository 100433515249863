import React from 'react';
import CampaignTile from 'components/Listing/components/ProductTileGrid/components/CampaignTile';
import ImageLegalLink from 'components/ImageLegalLink/ImageLegalLink';

import { isArrayEmpty } from '@xxxlgroup/hydra-utils/common';
import { parseHydraImage } from 'utils/imageUtils';
import type { CampaignType } from 'components/Listing/components/ProductTileGrid/components/Campaigns/Campaigns.types';
import styles from 'components/Listing/components/ProductTileGrid/ProductTileGrid.scss';

type PageAttributesType = { [key: string]: string | number };

const Campaigns = (campaign: CampaignType, pageAttributes: PageAttributesType) => {
  switch (campaign.type) {
    case 'cmsTextImageComponentWsDTO':
    case 'cmsImageComponentWsDTO': {
      const { columnSpan, image, link, name, poseidonLink, itemPosition, uuid } = campaign;
      const linkURL = poseidonLink?.url || link?.url || '';
      const linkName = poseidonLink?.linkName || link?.text?.[0] || '';
      return (
        <CampaignTile
          className={styles.productTileGrid}
          columnSpan={columnSpan}
          data-purpose="CampaignTile"
          image={image}
          key={uuid}
          link={linkURL}
          linkName={linkName}
          target="_blank"
          title={linkName as string}
          trackingData={{ id: uuid, name, resultGridPosition: itemPosition }}
        />
      );
    }
    case 'campaignWsDTO': {
      const {
        id,
        name,
        promotionTermsColor,
        promotionTermsLink,
        promotionTermsLinkOpen,
        promotionTermsText,
        itemPosition,
        searchResult,
        title,
      } = campaign;
      const { columnSpan, links = [], media } = searchResult?.searchResults?.[0] || {};
      const checkArrayData = links && !isArrayEmpty(links);
      const firstlink = links?.[0];
      const target = checkArrayData && firstlink?.openInNewWindow ? '_blank' : '_self';
      return (
        <CampaignTile
          className={styles.productTileGrid}
          columnSpan={columnSpan ?? 1}
          data-purpose="CampaignTile"
          image={parseHydraImage(media) ?? ''}
          key={id}
          link={checkArrayData ? firstlink?.url || '' : ''}
          linkName={checkArrayData ? firstlink?.alternativeText || '' : ''}
          promotionTerms={
            promotionTermsText &&
            promotionTermsLink && (
              <ImageLegalLink
                color={promotionTermsColor}
                id={id}
                link={promotionTermsLink}
                linkTarget={promotionTermsLinkOpen}
                name={name}
                text={promotionTermsText}
              />
            )
          }
          target={target}
          title={title ?? ''}
          {...pageAttributes}
          trackingData={{ id, name, resultGridPosition: itemPosition }}
        />
      );
    }
    default: {
      return null;
    }
  }
};

export default Campaigns;
