import classnames from 'classnames';
import React, { ComponentType, FC, useCallback, useEffect, useRef } from 'react';
import track from 'react-tracking';
import FormCheckbox from 'components/Form/components/FormCheckbox';
import FormInput from 'components/Form/components/FormInput';
import FormRadioGroup from 'components/Form/components/FormRadioGroup';
import FormSelectBox from 'components/Form/components/FormSelectBox';
import FieldFlag from 'components/Form/components/FieldFlag';
import Message from 'components/Message';
import FormSelectInput from 'components/Form/components/FormSelectInput/FormSelectInput';
import { tagComponent } from 'utils/tracking/tracking';
import { useTracking } from 'utils/tracking/hooks';
import { validateNotEmpty } from 'utils/validation';
import { ValidatorsType, FieldProps } from 'components/Form/components/Field/Field.types';

import styles from 'components/Form/components/Field/Field.scss';

type FieldTypes = {
  checkbox: ComponentType<any>;
  select: ComponentType<any>;
  radiogroup: ComponentType<any>;
  selectInput: ComponentType<any>;
};

const TYPES: FieldTypes = {
  checkbox: FormCheckbox,
  select: FormSelectBox,
  radiogroup: FormRadioGroup,
  selectInput: FormSelectInput,
};

const Field: FC<FieldProps> = (props) => {
  const {
    isBlock,
    className,
    isGrid,
    inputMode = 'text',
    name,
    flagCode,
    isRequired = false,
    size = 'medium',
    type = 'text',
    isFirstError = false,
    submitCount = 0,
    isValidateOnBlur,
    optionalSymbol,
    withOptionalSymbol,
    ...other
  } = props;

  const scrollRef = useRef<HTMLElement>(null);
  const prevFirstErrorRef = useRef<boolean>(false);
  const prevSubmitsRef = useRef<number>(0);

  const tracking = useTracking(props, 'Field');
  const scrollTo = () => {
    scrollRef.current?.scrollIntoView({ block: 'center' });
  };

  useEffect(() => {
    const prevFirstError = prevFirstErrorRef.current;
    const prevSubmits = prevSubmitsRef.current;
    // if it's the first field with an error, check if form has been submitted or the prev first error was different
    if (isFirstError && (!prevFirstError || prevSubmits < submitCount)) {
      scrollTo();
      (scrollRef.current?.firstElementChild as HTMLElement)?.focus();
    }
    prevFirstErrorRef.current = isFirstError;
    prevSubmitsRef.current = submitCount;
  }, [isFirstError, submitCount]);

  // Return type as unknown since there is a recursive circle of calls in this function.
  const validate = useCallback(
    (
      value: string,
      index: number,
      validators: ValidatorsType[],
      errorMessages: string | string[],
    ): unknown => {
      if (index < validators?.length) {
        return (
          (!validators[index].exec(value)
            ? { message: errorMessages[index], code: validators[index].code }
            : null) || validate(value, index + 1, validators, errorMessages)
        );
      }

      return null;
    },
    [],
  );

  const getValidators = useCallback(
    (validators: ValidatorsType[], errorMessages: string | string[]) => (value: string) => {
      const error = validate(value, 0, validators, errorMessages) as {
        message: string[];
        code: string;
      } | null;
      if (error) {
        tracking('error', { error: error.code, message: error.message });
        return error.message;
      }
      /* the validate prop now expects the validation function to return undefined if there is no error.
      Any other returned value (including falsy null, 0, etc will be treated as an error for the field).
      https://github.com/joepuzzo/informed/blob/master/CHANGELOG.md -> 2.0.0
      */
      return undefined;
    },
    [tracking, validate],
  );

  const renderField = useCallback(
    (isFieldRequired: boolean) => {
      const { validators } = props;
      const allValidators = isFieldRequired
        ? [validateNotEmpty() as ValidatorsType, ...((validators ?? []) as ValidatorsType[])]
        : validators;
      const errorMessageCodes = allValidators?.map(({ code }) => code);
      // ATTENTION: the value collection approach currently only works for one parameterized validator message!
      // This is sufficient for now (as we have max. one clientside parameterized validator for each field),
      // but we may have to rewrite the render/validate approach for the future.
      // Reason: Backend validators provide only numeric argument indices starting with {0}
      // Also note that this limitation is only valid for clientside validation. Displaying errors coming from BE is not affected at all.
      const allValuesReduced = allValidators?.reduce(
        (allValues, validator) =>
          validator?.values ? { ...allValues, ...validator.values } : allValues,
        {},
      );

      const FormElement = TYPES[type as keyof FieldTypes] ?? FormInput;

      return (
        <Message code={errorMessageCodes as string | string[]} values={allValuesReduced}>
          {(errorMessages) => (
            <FormElement
              forwardedRef={scrollRef}
              className={classnames(
                className,
                {
                  [styles.grid]: isGrid,
                  [styles.block]: isBlock,
                },
                styles[size],
              )}
              name={name}
              field={name}
              required={isFieldRequired}
              type={type}
              validate={getValidators(allValidators as ValidatorsType[], errorMessages)}
              validateOnBlur={isValidateOnBlur}
              {...(type === 'text' && { inputMode })}
              {...((type === 'text' || type === 'select') && {
                optionalSymbol,
                withOptionalSymbol,
              })}
              {...other}
            />
          )}
        </Message>
      );
    },
    [
      className,
      getValidators,
      inputMode,
      isBlock,
      isGrid,
      isValidateOnBlur,
      name,
      optionalSymbol,
      other,
      props,
      size,
      type,
      withOptionalSymbol,
    ],
  );

  return flagCode ? <FieldFlag code={flagCode}>{renderField}</FieldFlag> : renderField(isRequired);
};

export default track(tagComponent('Field'))(Field);
