import { noop } from '@xxxlgroup/hydra-utils/common';
import { session } from '@xxxlgroup/hydra-utils/storage';

const SESSION_KEY = 'debugCampaigns';

export const getDebugCampaigns = CONFIG.IS_SSR
  ? noop
  : (search: string): string[] => {
      const params = new URLSearchParams(search);
      const devModeCampaigns = params.get('devModeCampaigns');
      if (devModeCampaigns) {
        const campaigns = devModeCampaigns.split(',');
        session.setItem(SESSION_KEY, campaigns);
        return campaigns;
      }

      return session.getItem(SESSION_KEY);
    };

export const removeDebugCampaign = CONFIG.IS_SSR
  ? noop
  : (id: string) => {
      const currentCampaigns = session.getItem(SESSION_KEY) || [];
      const campaigns = currentCampaigns.filter((campaign: string) => campaign !== id);

      if (!campaigns.length) {
        session.removeItem(SESSION_KEY);
      } else if (currentCampaigns.length !== campaigns.length) {
        session.setItem(SESSION_KEY, campaigns);
      }

      return campaigns;
    };
