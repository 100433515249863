import React, { Component } from 'react';
import classnames from 'classnames';
// Components
import Layout from 'components/Layout';
import { Heading } from '@xxxlgroup/hydra-ui-components';
import Message from 'components/Message';
import LoggerContext from 'Providers/LoggerContext';

import styles from './ErrorBoundary.scss';

export type ErrorBoundaryProps = {
  /** @ignore */
  className?: string;
  /** @ignore */
  children?: React.ReactNode;
  /** error content   */
  errorChildren?: React.ReactNode;
  /** Error message that will be shown after a react error */
  message?: React.ReactNode;
  /** If true, change the statusCode if an error happens */
  withStatusCode?: boolean;
};

type ErrorBoundaryState = {
  hasError: boolean;
};

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state = { hasError: false };

  static contextType = LoggerContext;

  context!: React.ContextType<typeof LoggerContext>;

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    this.setState({ hasError: true });
    this.context(error, info, 'error');

    const { withStatusCode = false } = this.props;
    if (withStatusCode) {
      if (!CONFIG.IS_SSR) {
        // Status code for Puppeteer
        window.statusCode = 500;
      }
    }
  }

  render() {
    const {
      children = null,
      className = null,
      message = <Message code="error.boundary.general" />,
      errorChildren = null,
    } = this.props;
    if (this.state.hasError) {
      return (
        <Layout variant="full" className={classnames(styles.wrapper, className)}>
          {errorChildren || <Heading level={3}>{message}</Heading>}
        </Layout>
      );
    }
    return children;
  }
}

export default ErrorBoundary;
