import React, { FC } from 'react';
import { Image, Modal } from '@xxxlgroup/hydra-ui-components';
import Iframe from 'components/Iframe';
import { parseHydraImage } from 'utils/imageUtils';
import useMessage from 'components/Message/useMessage';
import Zoom from 'components/Zoom/Zoom.loader';
import type { EnergySideSheetProps } from 'components/EnergyEfficiency/EnergyEfficiency.types';

import styles from 'components/EnergyEfficiency/components/EnergySideSheet/EnergySideSheet.scss';

const EnergySideSheet: FC<EnergySideSheetProps> = ({
  energyEfficiencyData,
  hasInfo,
  isModalOpen,
  toggle,
}) => {
  const { datasheet, label } = energyEfficiencyData;

  const showLabelInfo = hasInfo && label;
  const showDatasheetInfo = hasInfo && !label && !!datasheet;

  const [heading] = useMessage(['pdp.energyEfficiency.sideSheet.heading']);

  /**
   * If there is no label information available (JPG file)
   * at least there should be a datasheet (PDF)
   * which will be then displayed
   * */
  const trackingProps = {
    isOpen: isModalOpen,
  };

  const renderEnergySheet = () => (
    <>
      {showLabelInfo && (
        <Zoom className={styles.imageZoomWrapper} withZoomResetWhenLeavingViewport>
          <Image source={parseHydraImage(label) ?? {}} />
        </Zoom>
      )}
      {showDatasheetInfo && (
        <Iframe
          wrapperClassName={styles.datasheetIframe}
          targetUrl={`${datasheet.url}#navpanes=0`}
          title={datasheet?.realFilename ?? ''}
          dataPurpose="product.energyEfficiency.iframe"
        />
      )}
    </>
  );

  const shouldModalOpen = ((label?.filename || label?.cdnFilename) ?? datasheet) && hasInfo;

  return shouldModalOpen ? (
    <Modal
      className={styles.energyEfficiencyModal}
      onClose={toggle(false)}
      heading={heading}
      variant="sidebar"
      {...trackingProps}
    >
      {renderEnergySheet()}
    </Modal>
  ) : null;
};

export default React.memo(EnergySideSheet);
