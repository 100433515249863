import getFeatureFlag from 'components/FeatureFlag/getFeatureFlags';

/**
 * Check status of one or more feature flags
 * @param {[Object]} featureFlags array of feature flag objects
 * @param {String | [String]} codes one or more feature flags
 * @param {'every' | 'some'} condition to use for combining flags
 * @returns {Boolean} returns true if feature flags of all provided codes are enabled
 */
export interface FeatureFlagStructure {
  code: string;
  enabled: boolean;
}

const isFeatureFlagEnabled = (
  featureFlags: FeatureFlagStructure[],
  codes: string | string[],
  condition: 'every' | 'some' = 'every',
) => getFeatureFlag(featureFlags, codes)[condition](Boolean);

export default isFeatureFlagEnabled;
