import React, { useEffect, useRef, useState } from 'react';
import track from 'react-tracking';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Button } from '@xxxlgroup/hydra-ui-components';
import { arrowUp } from '@xxxlgroup/hydra-icons';
import { DEBOUNCING_TIME_SHORT } from 'constantsDefinitions';
import { useTracking } from 'utils/tracking/hooks';
import { tagComponent } from 'utils/tracking/tracking';
import useMessage from 'components/Message/useMessage';
import styles from 'components/ScrollToTopButton/ScrollToTopButton.scss';
import { debouncing } from 'utils/function';

const debounceCall = debouncing((func) => func(), DEBOUNCING_TIME_SHORT);

const ScrollToTopButton = (props) => {
  const { hideDelay, isShownAt } = props;
  const [isShown, setIsShown] = useState(false);
  const prevPageYOffset = useRef(0);
  const scrollingTimeoutHandle = useRef(null);
  const scrollToTopMessage = useMessage('wxs.scrollToTop.button.content', null, true);
  const tracking = useTracking(props, 'ScrollToTopButton');

  const hideButtonWithDelay = () => {
    clearTimeout(scrollingTimeoutHandle.current);
    scrollingTimeoutHandle.current = setTimeout(() => {
      setIsShown(false);
    }, hideDelay);
  };

  const mouseOver = (event) => {
    tracking(event);
    clearTimeout(scrollingTimeoutHandle.current);
  };

  const click = (event) => {
    const content = document.querySelector('html');
    tracking(event);
    content.style.scrollBehavior = 'smooth';
    content.scrollTop = 0;
    content.style.scrollBehavior = 'auto';
  };

  const scrollLogic = () => {
    if (
      window.scrollY > window.innerHeight * isShownAt &&
      prevPageYOffset.current > window.scrollY
    ) {
      setIsShown(true);
      hideButtonWithDelay();
    } else {
      setIsShown(false);
    }

    prevPageYOffset.current = window.scrollY;
  };

  useEffect(() => {
    const handleScroll = () => {
      debounceCall(scrollLogic);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(scrollingTimeoutHandle.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Button
      data-purpose="scrollToTopButton.button"
      title={scrollToTopMessage}
      className={classnames(styles.button, { [styles.show]: isShown })}
      id="scrollToTopButton" // needed for querying this button in <StickyBar/>
      aria-hidden="true"
      onMouseEnter={mouseOver}
      onMouseLeave={hideButtonWithDelay}
      onFocus={mouseOver}
      onBlur={hideButtonWithDelay}
      onClick={click}
      glyphAfter={arrowUp}
      iconSize="large"
    />
  );
};

ScrollToTopButton.propTypes = {
  /** How long should you wait after the last scroll action in order to automatically hide the component. */
  hideDelay: PropTypes.number,
  /** How many window heights the user has to scroll down before the component is shown. */
  isShownAt: PropTypes.number,
};

ScrollToTopButton.defaultProps = {
  hideDelay: 4000,
  isShownAt: 1,
};

export default track(tagComponent('ScrollToTopButton'))(ScrollToTopButton);
