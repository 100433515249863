import { useContext } from 'react';
import { MediaQueryContext } from 'components/MediaQuery/components/MediaQueryProvider/MediaQueryProvider';
import {
  Breakpoint,
  type DeviceType,
  type UseMediaQueryProps,
} from 'components/MediaQuery/MediaQuery.types';

const breakpointOrder: Breakpoint[] = [
  Breakpoint.xs,
  Breakpoint.sm,
  Breakpoint.md,
  Breakpoint.lg,
  Breakpoint.xl,
  Breakpoint.xxl,
  Breakpoint.xxxl,
]; // has one extra item for "from xxl +1 until infinity"

const useMediaQuery = ({ largerThan, smallerThan, touchOnly = false }: UseMediaQueryProps) => {
  const mediaQueryContext = useContext(MediaQueryContext);

  const isLargerThan = (currentBreakpoint: Breakpoint, largerThan: Breakpoint) =>
    breakpointOrder.indexOf(currentBreakpoint) > breakpointOrder.indexOf(largerThan);

  const isSmallerThan = (currentBreakpoint: Breakpoint, smallerThan: Breakpoint) =>
    breakpointOrder.indexOf(currentBreakpoint) <= breakpointOrder.indexOf(smallerThan);

  const isCrossing = (largerThan: Breakpoint, smallerThan: Breakpoint) =>
    breakpointOrder.indexOf(largerThan) <= breakpointOrder.indexOf(smallerThan);

  const isTouch = (device: DeviceType) => {
    if (device && device.pointer) {
      return device.pointer === 'touch';
    }

    return false;
  };

  const getDimensionQueryCheck = (currentBreakpoint: Breakpoint) => {
    if (largerThan && smallerThan) {
      if (isCrossing(largerThan, smallerThan)) {
        return (
          isLargerThan(currentBreakpoint, largerThan) &&
          isSmallerThan(currentBreakpoint, smallerThan)
        );
      }

      return (
        isLargerThan(currentBreakpoint, largerThan) || isSmallerThan(currentBreakpoint, smallerThan)
      );
    }
    if (largerThan) {
      return isLargerThan(currentBreakpoint, largerThan);
    }
    if (smallerThan) {
      return isSmallerThan(currentBreakpoint, smallerThan);
    }

    return false;
  };

  const renderQuery = ({
    currentBreakpoint,
    device,
  }: {
    currentBreakpoint: Breakpoint;
    device: DeviceType;
  }) => {
    let queryCheck = getDimensionQueryCheck(currentBreakpoint);

    if (touchOnly) {
      if (!largerThan && !smallerThan) {
        queryCheck = isTouch(device);
      } else {
        queryCheck = queryCheck && isTouch(device);
      }
    }

    return queryCheck;
  };

  return renderQuery(mediaQueryContext);
};

export default useMediaQuery;
