import React, { FC } from 'react';
import classNames from 'classnames';
import track from 'react-tracking';
import { tagComponent } from 'utils/tracking/tracking';
import { Heading, HorizontalScrolling } from '@xxxlgroup/hydra-ui-components';
import { isArrayEmpty } from '@xxxlgroup/hydra-utils/common';
import { Image as ImageTypes } from '@xxxlgroup/hydra-ui-components/dist/common/types/typeDefinitions';
import { type HeadingLevel } from '@xxxlgroup/hydra-ui-components/dist/common/components/Heading/Heading.types';
import { getLevel } from 'utils/cmsUtils/cmsUtils';

import ServiceLink from 'components/Service/components/ServiceLink/ServiceLink';
import styles from 'components/Service/Service.scss';

interface ServiceListProps {
  /** Icon itself as SVG or image */
  icon?: ImageTypes;
  /** Text of each service item */
  iconName?: string;
  /** Text of each service item */
  name: string;
  /** Text of each service item */
  url: string;
  /** opens within same or new tab */
  target: string;
}

export interface ServiceProps {
  /** Creates a named anchor link to this component. */
  anchorId?: string;
  /** Color of the icon */
  iconColor?: string;
  /** Flag to determine if the component is for promotional purposes */
  isPromotion?: boolean;
  /** Array that holds all service items  */
  list: ServiceListProps[];
  /** Title of the services component */
  title?: string;
  /** Tag of Title of the services component */
  titleTag?: string;
  /** Variant view for Service Component */
  variant?: 'SLIDER' | 'LIST';
}

const Service: FC<ServiceProps> = (props) => {
  const {
    anchorId,
    iconColor = 'primary',
    isPromotion = false,
    list,
    title,
    titleTag = 'H2',
    variant = 'SLIDER',
  } = props;

  const isListVariant = variant === 'LIST';

  const renderHeading = (title?: string, titleTag?: string, anchorId?: string) =>
    title && (
      <Heading
        anchorId={anchorId}
        className={styles.heading}
        content={title}
        level={getLevel(titleTag ?? '', 4) as HeadingLevel}
      />
    );

  const renderList = (
    list: ServiceListProps[],
    iconColor: string,
    isPromotion: boolean,
    isListVariant?: boolean,
  ) => (
    <ul
      className={classNames(styles.list, { [styles['list-variant']]: isListVariant })}
      data-testid="services.variation.list"
    >
      {list?.map(
        ({ iconName, icon, name, url, target }) =>
          url && (
            <ServiceLink
              key={`${iconName}-${url}`}
              iconName={iconName}
              icon={icon}
              name={name}
              url={url}
              iconColor={iconColor}
              isListVariant={isListVariant}
              target={target}
              isPromotion={isPromotion}
            />
          ),
      )}
    </ul>
  );

  if (isArrayEmpty(list)) {
    return null;
  }

  return (
    <>
      {renderHeading(title, titleTag, anchorId)}
      {isListVariant ? (
        renderList(list, iconColor, isPromotion, isListVariant)
      ) : (
        <HorizontalScrolling className={styles.wrapper}>
          {renderList(list, iconColor, isPromotion, false)}
        </HorizontalScrolling>
      )}
    </>
  );
};

export default track(tagComponent('Service'))(Service);
