import React, { RefObject, useMemo } from 'react';
import {
  BasicFormProps,
  ChildFunction,
  FormApi,
  FormContext,
  Form as InformedForm,
} from 'informed';
import track from 'react-tracking';

import Block from 'components/Form/components/Block';
import Grid from 'components/Form/components/Grid';
import { FormFieldProvider } from 'components/Form/components/FormFieldProvider';

import { tagComponent } from 'utils/tracking/tracking';

interface InformedFormProps<T> extends BasicFormProps<T> {
  apiRef?: RefObject<FormApi | null>;
  id?: string;
}

export interface FormProps<T> extends InformedFormProps<T> {
  /** children will be automatically wrapped in a Block component */
  isBlock?: boolean;
  /** react children */
  children: React.ReactNode | ChildFunction<FormContext<T>>;
  /** className for external styling */
  className?: string;
  /** children will be automatically wrapped in a Grid component */
  isGrid?: boolean;
}

const Form = <T extends Record<string, any>>(props: FormProps<T>) => {
  const { children, isBlock, isGrid, ...other } = props;

  const wrappedChildren = useMemo(() => {
    if (isBlock) {
      return (
        <Block>
          <InformedForm noValidate {...other}>
            {children}
          </InformedForm>
        </Block>
      );
    }
    if (isGrid) {
      return (
        <Grid>
          <InformedForm noValidate {...other}>
            {children}
          </InformedForm>
        </Grid>
      );
    }
    return (
      <InformedForm noValidate {...other}>
        {children}
      </InformedForm>
    );
  }, [children, isBlock, isGrid, other]);

  return <FormFieldProvider>{wrappedChildren}</FormFieldProvider>;
};

export default track(tagComponent('Form'))(Form);
