import React, { type FormEvent, useContext, useEffect, useState } from 'react';
import { Heading, Input, Button } from '@xxxlgroup/hydra-ui-components';
import track from 'react-tracking';
import { tagComponent } from 'utils/tracking/tracking';
import { getSearchUrl, parseProductCode } from 'utils/url';
import { useHistory, useLocation } from 'react-router-dom';

import { useTracking } from 'utils/tracking/hooks';
import useMediaQuery from 'components/MediaQuery/useMediaQuery';

import CmsContent from 'components/CmsContent';
import Layout from 'components/Layout';
import Helmet from 'components/Helmet';
import SSRContext from 'Providers/SSRContext';
import ImageSearchButton from 'components/Header/components/ImageSearchButton';

import useMessage from 'components/Message/useMessage';
import styles from 'components/ErrorHandler/components/Error404/Error404.scss';
import useFeatureFlags from 'hooks/useFeatureFlags';

interface Error404Props {
  noIndex?: boolean;
  searchTerm?: string;
  isTrackingPageShown?: boolean;
  errorReason?: string;
}

const Error404 = (props: Error404Props) => {
  const {
    noIndex = false,
    searchTerm: searchKeyword,
    isTrackingPageShown = false,
    errorReason = null,
  } = props;
  const [searchTerm, setSearchTerm] = useState(searchKeyword);
  const tracking = useTracking(props, 'Error404');
  const history = useHistory();
  const isTouch = useMediaQuery({ touchOnly: true });
  const [isImageSearchEnabled] = useFeatureFlags('poseidon.searchBar.imageSearch.enabled');
  const { pathname } = useLocation();
  const productCode = parseProductCode(pathname);

  const [
    error404Content,
    error404SearchBarPlaceholder,
    error404SearchBarLabel,
    error404SearchBarButtonContent,
    inputCancelLabel,
    error404Headline,
  ] = useMessage([
    'wxs.error.404.content',
    'wxs.error.404.searchBar.placeholder',
    'wxs.error.404.searchBar.label',
    'wxs.error.404.searchBar.button.content',
    'wxs.input.cancel',
    'wxs.error.404.headline',
  ]);

  useEffect(() => {
    if (!CONFIG.IS_SSR) {
      if (isTrackingPageShown) {
        tracking('pageShown');
      }
      window.statusCode = 404;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (_event: Event, value: string) => {
    setSearchTerm(value);
  };

  const handleBlur = (event: React.FocusEvent<Element, Element>) => {
    tracking(event);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    tracking(event);
    event.preventDefault();
    history.push(getSearchUrl(searchTerm));
  };

  const ssrContext = useContext(SSRContext);

  if (CONFIG.IS_SSR) {
    // Status code for Node.js SSR. We do not want to overwrite a 500 code with a 404!
    if (ssrContext) {
      if (!ssrContext.statusCode || ssrContext.statusCode <= 499) {
        ssrContext.statusCode = 404;
      }
      if (errorReason) {
        // Add to existing error reasons
        ssrContext.errorReasons.add(errorReason);
      }
    }
  }

  return (
    <>
      {noIndex && <Helmet noIndex />}
      <Layout className={styles.errorContainer} variant="narrow">
        <Heading data-purpose="error404.heading" level={2} SEO="h1" content={error404Headline} />
        <div className={styles.message} data-purpose="error404.content">
          {error404Content}
        </div>
        <form className={styles.form} onSubmit={handleSubmit}>
          <Input
            className={styles.search}
            data-purpose="error404.input.text"
            label={error404SearchBarLabel}
            hideLabel={false}
            inputMode="search"
            isTouch={isTouch}
            i18n={{ cancel: inputCancelLabel }}
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder={error404SearchBarPlaceholder}
            suffix={isImageSearchEnabled ? <ImageSearchButton /> : undefined}
            value={searchTerm}
          />
          <Button
            data-purpose="error404.button.submit"
            className={styles.button}
            variant="primary"
            type="submit"
          >
            {error404SearchBarButtonContent}
          </Button>
        </form>
      </Layout>
      <Layout>
        <CmsContent seo pageCode="404" productCode={productCode} hideError />
      </Layout>
    </>
  );
};

export default track(tagComponent('Error404'))(Error404);
