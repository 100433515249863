import React, { FC, ReactNode, useMemo } from 'react';
import { FORM_FIELD_QUERY } from 'components/Form/components/FormFieldProvider/FormField.query';
import { useQuery } from '@apollo/client';
import { ApplicationFeature } from 'graphql-types/generated/types-generated';

interface FormFieldProviderProps {
  /** Children component */
  children: ReactNode;
  /** if true don't load features(used for testing) */
  noFeatures?: boolean;
}

export interface FormFieldContextValue {
  loading: boolean;
  features: ApplicationFeature[];
}

export const FormFieldContext = React.createContext<Record<string, any>>({});

const FormFieldProvider: FC<FormFieldProviderProps> = ({ children, noFeatures = false }) => {
  const { data: { getFeatures: { features = [] } = {} } = {}, loading } = useQuery(
    FORM_FIELD_QUERY,
    {
      variables: {
        context: 'FORM_FIELD',
      },
      skip: noFeatures,
    },
  );
  const value = useMemo(() => ({ loading, features }), [loading, features]);

  return <FormFieldContext.Provider value={value}>{children}</FormFieldContext.Provider>;
};

export default FormFieldProvider;
