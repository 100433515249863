import React, { createContext, useEffect, useState, useMemo } from 'react';
import { useApolloClient } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import useLanguage from 'hooks/useLanguage';

import { CMSCONTENT_PAGE_QUERY } from 'components/CmsContent/CmsContent.query';

const TOTA11Y_SCRIPT_ID = 'tota11yscript';
const TOTA11Y_SRC = '/xxxlsmarteditmodule/xxxl-tota11y.js';

const injectTota11y = () => {
  const scriptElement = document.createElement('script');
  scriptElement.id = TOTA11Y_SCRIPT_ID;
  scriptElement.type = 'text/javascript';
  scriptElement.src = TOTA11Y_SRC;
  document.body.appendChild(scriptElement);
};

export const isInSmartEdit = () => {
  try {
    // if we are within an iframe, an uncaught DOMException will fire
    return CONFIG.IS_SSR ? false : !!window.parent?.smartEditBootstrapped;
  } catch (e) {
    if (e instanceof DOMException) {
      return false;
    }

    throw e;
  }
};

export const SmartEditContext = createContext({ isInSmartEdit: isInSmartEdit() });

export interface SmartEditHandlingProp {
  children: React.ReactNode | React.ReactNode[];
}

export const SmartEditHandling = ({ children }: SmartEditHandlingProp) => {
  const client = useApolloClient();
  const { search } = useLocation();
  const { languageCode } = useLanguage();

  const [cmsTicketId, setCmsTicketId] = useState(
    typeof window !== 'undefined' && window.isSmartEditView
      ? `default-cmsTicket-${languageCode}`
      : '',
  );
  const [keepCache, setKeepCache] = useState('');

  useEffect(() => {
    const UrlTickedID = new URLSearchParams(search).get('cmsTicketId');
    const urlKeepCache = new URLSearchParams(search).get('keepCache');
    urlKeepCache && setKeepCache(urlKeepCache);
    UrlTickedID && setCmsTicketId(UrlTickedID);
  }, [search]);

  if (cmsTicketId) {
    const { getContentPage = null } =
      client.readQuery({
        query: CMSCONTENT_PAGE_QUERY,
      }) ?? {};

    client.writeQuery({
      data: { cmsTicketId, keepCache, isInSmartEdit: true, getContentPage },
      query: CMSCONTENT_PAGE_QUERY,
    });
    if (
      window.location.href.match(/^.*\bpreviewServlet\b.*$/i) &&
      !document.querySelector(`#${TOTA11Y_SCRIPT_ID}`)
    ) {
      injectTota11y();
    }
  }
  const value = useMemo(
    () => ({
      isInSmartEdit: !!cmsTicketId,
    }),
    [cmsTicketId],
  );

  return <SmartEditContext.Provider value={value}>{children}</SmartEditContext.Provider>;
};

export default SmartEditHandling;
