import React, { useCallback } from 'react';
import { Heading, Subheading } from '@xxxlgroup/hydra-ui-components';
import { SEOLevel } from '@xxxlgroup/hydra-ui-components/dist/common/types/typeDefinitions';
import { HeadingLevel } from '@xxxlgroup/hydra-ui-components/dist/common/components/Heading/Heading.types';
import { SubheadingLevel } from '@xxxlgroup/hydra-ui-components/dist/common/components/Subheading/Subheading.types';
import track from 'react-tracking';
import { tagComponent } from 'utils/tracking/tracking';
import { getLevel, SMALLEST_FONTSIZE, SMALLEST_SEO } from 'utils/cmsUtils/cmsUtils';
import { openCookieModal } from 'utils/openCookieModal';
import { useTracking } from 'utils/tracking/hooks';
import { trackRichText } from 'utils/tracking/trackingRichText';
import ContentExtended, { ContentSizeProp } from 'components/ContentExtended/ContentExtended';

const componentType = 'Paragraph';

export type FontSizeProps = 'H1' | 'H2' | 'H3' | 'H4' | 'H5' | 'H6' | 'H7';

export interface ParagraphProps {
  /** Creates a named anchor link to this component. */
  anchorId?: string;
  /** Content of the component */
  content: string;
  /** Sets a className to customize content styles */
  contentClassName?: string;
  /** Sets the font size for the component */
  contentSize?: 'XS' | 'SM' | 'RG' | 'LG';
  /** Sets the id for the component */
  id: string;
  /** Styling of the component, including font size of the title, only H1 to H7 are used, H5-H7 are using for hydra subheading component */
  style?: {
    fontSize?: FontSizeProps;
  };
  /** Prop to define styling for subtitle  */
  hasStrongSubtitleStyle?: boolean;
  /** Level of the title tag, for SEO purpose, only H1 to H4 are used */
  tag?: 'H1' | 'H2' | 'H3' | 'H4';
  /** Title of the component */
  title?: string;
  /** Title class of the component */
  titleClassName?: string;
}

const Paragraph = (props: ParagraphProps) => {
  const {
    anchorId,
    content,
    contentClassName,
    contentSize = 'RG',
    id,
    hasStrongSubtitleStyle = false,
    style,
    tag = null,
    title,
    titleClassName,
  } = props;

  const tracking = useTracking(props, componentType);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLOrSVGElement>) => {
      if (event.target instanceof HTMLElement) {
        event.target.tagName === 'A' && openCookieModal(event, event.target.getAttribute('href'));
      }
      trackRichText(event, tracking);
    },
    [tracking],
  );

  const renderHeading = (headingContent: string) => {
    const fontSize = style?.fontSize
      ? Number(getLevel(style.fontSize, SMALLEST_FONTSIZE) || 0)
      : SMALLEST_FONTSIZE;
    const seoLevel = tag ? (`h${getLevel(tag, SMALLEST_SEO)}` as SEOLevel) : true;

    return fontSize <= 4 ? (
      <Heading
        SEO={seoLevel}
        anchorId={anchorId}
        className={titleClassName}
        content={headingContent}
        key={`${id}-heading`}
        level={fontSize as HeadingLevel}
      />
    ) : (
      <Subheading
        anchorId={anchorId}
        className={titleClassName}
        content={headingContent}
        hasEmphasizedStyle={hasStrongSubtitleStyle}
        key={`${id}-subheading`}
        level={(fontSize - 4) as SubheadingLevel}
      />
    );
  };

  return (
    <>
      {title && renderHeading(title)}
      {content && (
        <ContentExtended
          className={contentClassName}
          content={content}
          key={`${id}-content`}
          onClick={handleClick}
          data-purpose="cmsParagraph.link"
          size={contentSize && (contentSize.toLowerCase() as ContentSizeProp)}
        />
      )}
    </>
  );
};

export default track(tagComponent(componentType))(Paragraph);
