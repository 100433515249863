import React, { RefObject, useEffect, useRef } from 'react';
import classnames from 'classnames';
import styles from 'components/AnchorBar/components/Anchor/Anchor.scss';

interface AnchorType {
  className?: string;
  isActive?: boolean;
  isParentFixed?: boolean;
  isHidden?: boolean;
  linkName: string;
  onSelect?: () => void;
}

const Anchor = ({
  className = '',
  isActive = false,
  isParentFixed = false,
  isHidden = false,
  linkName,
  onSelect = undefined,
}: AnchorType) => {
  const anchorRef: RefObject<HTMLButtonElement> = useRef(null);
  const btnClassName = classnames(
    styles.anchor,
    {
      [styles.active]: isActive,
      [styles.hidden]: isHidden,
    },
    className,
  );

  useEffect(() => {
    if (isParentFixed && isActive && anchorRef.current?.parentElement) {
      anchorRef.current.parentElement.scroll({
        left:
          anchorRef.current.offsetLeft +
          anchorRef.current.clientWidth -
          anchorRef.current.parentElement.clientWidth,
        behavior: 'smooth',
      });
    }
  }, [isParentFixed, isActive]);

  return (
    <button ref={anchorRef} className={btnClassName} key={linkName} onClick={onSelect}>
      {linkName}
    </button>
  );
};

export default Anchor;
