import React from 'react';
import PropTypes from 'prop-types';

import SmartEditHandler from 'components/SmartEditHandling';
import { MediaQueryProvider } from 'components/MediaQuery/components/MediaQueryProvider';
import { AuthContextProvider } from 'modules/customer-authentication/AuthContext';
import { FavouritesProvider } from 'modules/favourites/pages/Favourites/Favourites.state';
import { ImageSearchContextProvider } from 'pages/ImageSearch/ImageSearch.provider';
import { GreetingContextProvider } from 'modules/customer-account/components/GreetingContextProvider/GreetingContextProvider';
import { ApplicationDataProvider } from 'pages/App/components/ApplicationDataProvider';
import HeroProvider from 'cms/CmsHeroComponent/components/HeroProvider';
import { CategoryProvider } from 'pages/CategoryView/provider/Category.provider';
import AddToCartSidebarProvider from 'components/AddToCartSidebar/components/Provider/AddToCartSidebarProvider';

export const baseProviders = [
  GreetingContextProvider,
  FavouritesProvider,
  ImageSearchContextProvider,
  AuthContextProvider,
  CategoryProvider,
  SmartEditHandler,
  MediaQueryProvider,
  ApplicationDataProvider,
  AddToCartSidebarProvider,
  HeroProvider,
];

const ContainerBase = ({ providers = [], children }) =>
  [...baseProviders, ...providers].reduce(
    (current, AppProvider) => <AppProvider>{current}</AppProvider>,
    children,
  );

ContainerBase.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ContainerBase;
