import React from 'react';
import Link from 'components/WebshopLink/WebshopLink';
import { Link as HydraLink } from '@xxxlgroup/hydra-ui-components';
import { noop } from '@xxxlgroup/hydra-utils/common';
import styles from 'components/ProductSlider/ProductSlider.scss';

export const getLastItemOverlay = (text: string, length: number, href = '', extended = false) => (
  <Link
    className={styles.overlayLink}
    href={href}
    as={<div data-childcount={length} data-extended={extended} />}
  >
    <HydraLink theme="dark" className={styles.linkText} typography="regular" link="span" underline>
      {text}
    </HydraLink>
  </Link>
);

export const getShowMoreLink = (
  text: string,
  length: number,
  href: string,
  extended = false,
  onClick = noop,
  onHover = noop,
) => (
  <Link
    onClick={onClick}
    className={styles.showMoreLink}
    onMouseEnter={onHover}
    onFocus={onHover}
    as={
      <HydraLink
        theme="dark"
        typography="small"
        underline
        data-childcount={length}
        data-extended={extended}
      />
    }
    href={href}
  >
    {text}
  </Link>
);
