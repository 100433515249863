import { rail } from '@xxxlgroup/hydra-config';

/**
 * This file contains default meta-tags for which can later on be overwritten by other pages.
 * Language based meta tags are only represented within their language.
 */

export const defaultMetaTags = [
  {
    name: 'viewport',
    content: 'width=device-width, initial-scale=1, minimum-scale=1, shrink-to-fit=no',
  },
  { name: 'format-detection', content: 'telephone=no' },
  {
    property: 'og:image',
    content: `${rail.baseImageUrl}${CONFIG.RAIL_NAME}SharableLogo?w=1200&h=630`,
  },
];

export const countryBasedMetaTags: { [key: string]: string } = {};
