import React, {
  ComponentType,
  FC,
  FocusEvent,
  MouseEvent,
  ReactNode,
  RefObject,
  useCallback,
} from 'react';
import { Card, CardImage, CardOverlay } from '@xxxlgroup/hydra-ui-components';
import type { Image as ImageType } from '@xxxlgroup/hydra-ui-components/dist/common/types/typeDefinitions';
import type { Target as cardTargetType } from '@xxxlgroup/hydra-ui-components/dist/common/molecules/Card/Card.types';
import { config } from '@xxxlgroup/hydra-config';
import withLinkModifier from '@xxxlgroup/hydra-ui-components/dist/common/molecules/Card/hoc/withLinkModifier';
import track from 'react-tracking';

import classNames from 'classnames';
import styles from 'components/Listing/components/ProductTileGrid/components/CampaignTile/CampaignTile.scss';
import Link from 'components/WebshopLink';
import { tagComponent } from 'utils/tracking/tracking';
import { useImpressionTracker } from 'utils/tracking/hooks';

interface CampaignTileProps {
  /** @ignore */
  className: string;
  /** campaign will spread over `colSpan` columns */
  columnSpan?: number;
  /** Data attribute for the first product per page */
  'data-page'?: number;
  /** Data-purpose attribute for product */
  'data-purpose'?: string;
  /** background campaign image, TODO: use with hashCode if provided over api */
  image: string | ImageType; // Types.image.isRequired,
  /** campaign call-to-action url */
  link?: string;
  /** campaign call-to-action link name */
  linkName: string;
  /** ref for the first item per page */
  pageRef?: RefObject<HTMLElement>; // ReactTypes.ref,
  /** When provided, a link at the bottom should appear with customisable URL, text and color */
  promotionTerms?: ReactNode;
  /** campaign item should be opened in new or same tab */
  target?: cardTargetType;
  /** campaign title as card heading */
  title: string;
  /** Tracking props for FH campaigns: name, id, resultGridPosition  */
  trackingData: {
    name?: string | null;
    id?: string | null;
    resultGridPosition?: number | null;
  };
}

const getImageSizes = (column: number) => {
  const {
    breakpoints: { lg, md, xs },
    srcSetImageSizes,
  } = config;
  return `(min-width: ${lg}px) ${srcSetImageSizes[column === 2 ? 4 : 6]}px,
              (min-width: ${md}px) ${33 * column}vw,
              (max-width: ${xs}px) ${srcSetImageSizes[column === 2 ? 4 : 1]}px,
              ${50 * column}vw`;
};

const CampaignTile: FC<CampaignTileProps> = (props) => {
  const {
    className,
    columnSpan = 1,
    'data-page': dataPage,
    'data-purpose': dataPurpose,
    image,
    link,
    linkName,
    pageRef = null,
    promotionTerms,
    target = '_blank',
    title,
    trackingData,
  } = props;
  const { name, id, resultGridPosition } = trackingData;
  const imageSizes = getImageSizes(columnSpan);
  const { setRef, tracking } = useImpressionTracker(
    { ...props, name, resultGridPosition },
    'CampaignTile',
    // all the tracking should be moved to TS first - the problem here is with 'data' default set to null
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    { id },
  );

  const handleTracking = useCallback(
    (event: MouseEvent<HTMLDivElement> | FocusEvent<HTMLDivElement>) => {
      tracking(event, { id });
    },
    [tracking, id],
  );

  return (
    <div
      ref={setRef}
      className={classNames(styles[`colspan-${Math.min(columnSpan, 4)}`], className)}
    >
      <Card
        data-purpose={dataPurpose}
        hasBorder={false}
        onMouseEnter={handleTracking}
        onFocus={handleTracking}
        onClick={handleTracking}
        overlay={
          title ? (
            <CardOverlay
              className={classNames(promotionTerms && styles.overlayWithTerms)}
              content={<CardImage isBackground imageSizes={imageSizes} source={image} />}
              hasLink={!!link}
            >
              {title}
            </CardOverlay>
          ) : null
        }
        renderLink={
          link &&
          withLinkModifier(Link as ComponentType)({
            ariaLabel: linkName,
            href: link,
            'data-track-id': 'redirectSubSelector',
          })
        }
        target={target}
        variant="image"
      >
        <span ref={pageRef} data-page={dataPage} />
        {(!title || !link) && <CardImage isBackground imageSizes={imageSizes} source={image} />}
        {promotionTerms}
      </Card>
    </div>
  );
};

export default track(tagComponent('CampaignTile'))(CampaignTile);
