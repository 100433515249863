import React, { MouseEventHandler, useMemo } from 'react';
import { Image } from '@xxxlgroup/hydra-ui-components';
import Link from 'components/WebshopLink';
import { config } from '@xxxlgroup/hydra-config';
import { getProductUrl } from 'utils/url';
import { getCurrentProduct, getElementIndex } from 'components/ProductTile/utils';
import { parseHydraImage } from 'utils/imageUtils';

import type { ColorVariantData } from 'components/ProductTile/ProductTile.types';
import type { ProductPriceData, VariantGroup } from 'graphql-types/generated/types-generated';

const THUMBNAIL_LIMIT = 3;

export interface ProductVariant {
  availabilityStatus?: string | null;
  code?: string | null;
  name?: string | null;
  priceData?: ProductPriceData | null;
  url?: string | null;
}
interface HookProps {
  colorVariants: VariantGroup;
  currentDisplayedProduct: ProductVariant;
  setCurrentDisplayedProduct: (product: ProductVariant) => void;
  styles: {
    [className: string]: string;
  };
  variants?: ColorVariantData[];
}

const useProductTileVariants = ({
  colorVariants,
  currentDisplayedProduct,
  setCurrentDisplayedProduct,
  styles,
  variants,
}: HookProps): JSX.Element[] =>
  useMemo(() => {
    if (!variants) {
      return [];
    }

    const { availabilityStatus, priceData, name, url, code } = currentDisplayedProduct;
    const { count: variantsCount = 0 } = colorVariants || {};
    const numberOfNotVisibleImages = variantsCount && variantsCount - THUMBNAIL_LIMIT;
    const hasThumbnailLimit = variantsCount && variantsCount > THUMBNAIL_LIMIT + 1;
    const reducedVariantData = variants.slice(0, THUMBNAIL_LIMIT + 1);

    const handleMouseLeaveVariant = () => {
      setCurrentDisplayedProduct({ availabilityStatus, priceData, name, url, code });
    };

    const handleMouseEnterVariant: MouseEventHandler<HTMLAnchorElement> = (event) => {
      const elementIndex = getElementIndex(event.currentTarget);
      const currentProduct = getCurrentProduct(colorVariants, elementIndex);
      const currentUrl =
        currentProduct?.url ?? (currentProduct?.code && getProductUrl(currentProduct.code));

      setCurrentDisplayedProduct({
        availabilityStatus: currentProduct?.availabilityStatus,
        priceData: currentProduct?.priceData || priceData,
        name: currentProduct?.name || name,
        url: currentUrl,
        code: currentProduct?.code,
      });
    };

    const variantLinkAttributes = {
      'data-purpose': 'variant.image.link',
      onBlur: handleMouseLeaveVariant,
      onFocus: handleMouseEnterVariant,
      onMouseEnter: handleMouseEnterVariant,
      onMouseLeave: handleMouseLeaveVariant,
    };

    const imageSizes = `(min-width: ${config.breakpoints.lg}px) 350px, (min-width: ${config.breakpoints.xs}px) 245px, 350px`;

    return reducedVariantData?.map((item: ColorVariantData, index: number): JSX.Element => {
      if (index === THUMBNAIL_LIMIT && hasThumbnailLimit) {
        return (
          <Link
            {...variantLinkAttributes}
            className={styles.productLink}
            key={item.cdnFilename}
            href={item.productUrl}
            withReplace={false}
          >
            <Image
              altText={item.image?.altText ?? ''}
              className={styles.lastVariantImage}
              sizes={imageSizes}
              source={parseHydraImage(item.image) ?? ''}
              useSrcSet
              loading="lazy"
            />
            {`+${numberOfNotVisibleImages}`}
          </Link>
        );
      }
      return (
        <Link
          {...variantLinkAttributes}
          key={item.cdnFilename}
          href={item.productUrl}
          withReplace={false}
        >
          <Image
            altText={item.image?.altText ?? ''}
            sizes={imageSizes}
            source={parseHydraImage(item.image) ?? ''}
            useSrcSet
            loading="lazy"
          />
        </Link>
      );
    });
  }, [
    colorVariants,
    currentDisplayedProduct,
    setCurrentDisplayedProduct,
    styles.lastVariantImage,
    styles.productLink,
    variants,
  ]);

export default useProductTileVariants;
