import React from 'react';

import loadable, { LoadableComponent } from '@loadable/component';
import {
  initialContext,
  useAddToCartSidebarContext,
} from 'components/AddToCartSidebar/components/Provider/AddToCartSidebarProvider';
import useUnitBasedCalculationSidebar from 'modules/listing/components/Product/hooks/useUnitBasedCalculationSidebar';
import { initialStateUnitBasedCalculation } from 'pages/ProductDetail/Product.state';
import { OnAddToCartEndedValue } from 'pages/ProductDetail/ProductDetails.types';
import useProductService from 'modules/listing/components/Product/hooks/useProductService';

const UnitCalculatorModal: LoadableComponent<any> = loadable(
  () => import('components/UnitCalculatorModal') as any,
  { ssr: false },
);

const AddToCartSidebar: LoadableComponent<any> = loadable(
  () => import('components/AddToCartSidebar') as any,
  { ssr: false },
);

const AddToCartSidebarContainer = () => {
  const { sidebarProviderState, setSidebarProviderState } = useAddToCartSidebarContext();
  const { sidebarData, isAddToCartSidebarOpen, isUnitBasedSidebar } = sidebarProviderState;
  const { state, stateSetters } = useUnitBasedCalculationSidebar(
    sidebarProviderState.sidebarData?.product?.priceData,
  );
  const { setUnitBasedCalculation } = stateSetters;

  const { service } = useProductService(sidebarProviderState.sidebarData?.product?.productCode);

  const handleCloseSidebar = () => {
    setSidebarProviderState({ ...initialContext.sidebarProviderState });

    setUnitBasedCalculation(initialStateUnitBasedCalculation);
  };

  const handleOnAddToCartEnded = (entryData: OnAddToCartEndedValue) => {
    setSidebarProviderState({
      isAddToCartSidebarOpen: true,
      sidebarData: {
        ...entryData,
        unitBasedCalculation: state.unitBasedCalculation,
      },
      isUnitBasedSidebar: false,
    });
  };

  const {
    addedEntry,
    cartMessages,
    errors,
    potentialOrderPromotions,
    potentialProductPromotions,
    unitBasedCalculation,
  } = sidebarData;

  return (
    isAddToCartSidebarOpen &&
    (isUnitBasedSidebar ? (
      <UnitCalculatorModal
        onAddToCartEnded={handleOnAddToCartEnded}
        onClose={handleCloseSidebar}
        onUnitCalculationEnded={setUnitBasedCalculation}
        product={sidebarData?.product}
        service={service}
        unitBasedCalculation={state.unitBasedCalculation}
      />
    ) : (
      <AddToCartSidebar
        cartMessages={cartMessages}
        errors={errors}
        onClose={handleCloseSidebar}
        orderEntry={addedEntry}
        potentialOrderPromotions={potentialOrderPromotions}
        potentialProductPromotions={potentialProductPromotions}
        unitBasedCalculation={unitBasedCalculation}
      />
    ))
  );
};

export default AddToCartSidebarContainer;
